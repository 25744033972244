import { makeAutoObservable } from 'mobx';
import { ErrorsTracker } from '@api/errorsTracker';
import { SocketClient } from '@api/SocketClient';
import { UUID } from '@api/fbe/uuid';
import { convertEmailTemplateRecordToModel, EmailTemplateModel } from '@models/EmailTemplates';
import { EmailTemplatesGetAllRequest } from '@api/fbe/manager/EmailTemplatesGetAllRequest';
import { EmailTemplateCreateRequest } from '@api/fbe/manager/EmailTemplateCreateRequest';
import { EventType } from '@api/fbe/core/EventType';
import { EmailTemplateModifyRequest } from '@api/fbe/manager/EmailTemplateModifyRequest';
import { EmailTemplateDeleteRequest } from '@api/fbe/manager/EmailTemplateDeleteRequest';

export class EmailTemplatesStore {
    errorTracker = new ErrorsTracker({ title: 'Email Templates' });

    constructor() {
        makeAutoObservable(this);
    }

    data: EmailTemplateModel[] = [];

    isLoading = false;

    setData(value: EmailTemplateModel[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    getDailyEmailTemplates(){
        return this.data.filter((item: EmailTemplateModel) => item.EventType === EventType.DAILY_CLIENT_REPORT.valueOf())
    }

    getMonthlyEmailTemplates(){
        return this.data.filter((item: EmailTemplateModel) => item.EventType === EventType.MONTHLY_CLIENT_REPORT.valueOf())
    }

    @ErrorsTracker.wrapApi()
    async get() {
        const request = new EmailTemplatesGetAllRequest();
        this.setIsLoading(true);
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setData(data.EmailTemplates.map(convertEmailTemplateRecordToModel));
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async create(newEmailTemplate: EmailTemplateModel) {
        const request = new EmailTemplateCreateRequest();

        request.Name = newEmailTemplate.Name;
        request.Comment = newEmailTemplate.Comment;
        request.Description = newEmailTemplate.Description;
        request.EventType = new EventType(newEmailTemplate.EventType);
        request.EmailSubject = newEmailTemplate.EmailSubject;
        request.EmailBody = newEmailTemplate.EmailBody;

        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(updatedEmailTemplate: EmailTemplateModel) {
        const request = new EmailTemplateModifyRequest();
        request.EmailTemplateId = UUID.fromString(updatedEmailTemplate.id);
        request.NewName = updatedEmailTemplate.Name;
        request.NewComment = updatedEmailTemplate.Comment;
        request.NewDescription = updatedEmailTemplate.Description;
        request.NewEventType = new EventType(updatedEmailTemplate.EventType);
        request.NewEmailSubject = updatedEmailTemplate.EmailSubject;
        request.NewEmailBody = updatedEmailTemplate.EmailBody;
        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async delete(id: string) {
        const request = new EmailTemplateDeleteRequest();
        request.EmailTemplateId = UUID.fromString(id);
        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

const emailTemplatesStore = new EmailTemplatesStore();
export { emailTemplatesStore };

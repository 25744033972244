
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * NotificationServiceSettings struct
 */
class NotificationServiceSettings {
  /**
   * Initialize struct
   * @param {!string=} host
   * @param {!number=} port
   * @param {!boolean=} useSsl
   * @param {!string=} environment
   * @param {!number=} notificationTTLInMinutes
   * @param {!number=} responseTimeoutInSeconds
   * @param {!number=} maxBatchSize
   * @constructor
   */
  constructor (host = '', port = 0, useSsl = false, environment = '', notificationTTLInMinutes = 5, responseTimeoutInSeconds = new UInt64(30, 0), maxBatchSize = 100) {
    this.Host = host
    this.Port = port
    this.UseSsl = useSsl
    this.Environment = environment
    this.NotificationTTLInMinutes = notificationTTLInMinutes
    this.ResponseTimeoutInSeconds = responseTimeoutInSeconds
    this.MaxBatchSize = maxBatchSize
  }

  /**
   * Copy struct (shallow copy)
   * @this {!NotificationServiceSettings}
   * @param {!NotificationServiceSettings} other Other struct
   * @returns {!NotificationServiceSettings} This struct
   */
  copy (other) {
    if (other.Host != null) {
      this.Host = other.Host
    } else {
      this.Host = null
    }
    if (other.Port != null) {
      this.Port = other.Port
    } else {
      this.Port = null
    }
    if (other.UseSsl != null) {
      this.UseSsl = other.UseSsl
    } else {
      this.UseSsl = null
    }
    if (other.Environment != null) {
      this.Environment = other.Environment
    } else {
      this.Environment = null
    }
    if (other.NotificationTTLInMinutes != null) {
      this.NotificationTTLInMinutes = other.NotificationTTLInMinutes
    } else {
      this.NotificationTTLInMinutes = null
    }
    if (other.ResponseTimeoutInSeconds != null) {
      this.ResponseTimeoutInSeconds = UInt64.fromNumber(other.ResponseTimeoutInSeconds)
    } else {
      this.ResponseTimeoutInSeconds = null
    }
    if (other.MaxBatchSize != null) {
      this.MaxBatchSize = other.MaxBatchSize
    } else {
      this.MaxBatchSize = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!NotificationServiceSettings}
   * @returns {!NotificationServiceSettings} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new NotificationServiceSettingsModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new NotificationServiceSettingsModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!NotificationServiceSettings}
   * @param {!NotificationServiceSettings} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof NotificationServiceSettings)) {
      throw new TypeError('Instance of NotificationServiceSettings is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!NotificationServiceSettings}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Host: ((this.Host != null) ? this.Host : null),
      Port: ((this.Port != null) ? this.Port : null),
      UseSsl: ((this.UseSsl != null) ? this.UseSsl : null),
      Environment: ((this.Environment != null) ? this.Environment : null),
      NotificationTTLInMinutes: ((this.NotificationTTLInMinutes != null) ? this.NotificationTTLInMinutes : null),
      ResponseTimeoutInSeconds: ((this.ResponseTimeoutInSeconds != null) ? this.ResponseTimeoutInSeconds.toNumber() : null),
      MaxBatchSize: ((this.MaxBatchSize != null) ? this.MaxBatchSize : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return NotificationServiceSettings.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!NotificationServiceSettings} other Object value
   * @returns {!NotificationServiceSettings} Created struct
   */
  static fromObject (other) {
    return new NotificationServiceSettings().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!NotificationServiceSettings}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return NotificationServiceSettings.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!NotificationServiceSettings}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 214
  }
}

export { NotificationServiceSettings };

/**
 * Fast Binary Encoding NotificationServiceSettings field model
 */
class FieldModelNotificationServiceSettings extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Host = new fbe.FieldModelString(buffer, 4 + 4)
    this.Port = new fbe.FieldModelUInt16(buffer, this.Host.fbeOffset + this.Host.fbeSize)
    this.UseSsl = new fbe.FieldModelBool(buffer, this.Port.fbeOffset + this.Port.fbeSize)
    this.Environment = new fbe.FieldModelString(buffer, this.UseSsl.fbeOffset + this.UseSsl.fbeSize)
    this.NotificationTTLInMinutes = new fbe.FieldModelUInt32(buffer, this.Environment.fbeOffset + this.Environment.fbeSize)
    this.ResponseTimeoutInSeconds = new fbe.FieldModelUInt64(buffer, this.NotificationTTLInMinutes.fbeOffset + this.NotificationTTLInMinutes.fbeSize)
    this.MaxBatchSize = new fbe.FieldModelUInt16(buffer, this.ResponseTimeoutInSeconds.fbeOffset + this.ResponseTimeoutInSeconds.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelNotificationServiceSettings}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelNotificationServiceSettings}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Host.fbeSize + this.Port.fbeSize + this.UseSsl.fbeSize + this.Environment.fbeSize + this.NotificationTTLInMinutes.fbeSize + this.ResponseTimeoutInSeconds.fbeSize + this.MaxBatchSize.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelNotificationServiceSettings}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Host.fbeExtra + this.Port.fbeExtra + this.UseSsl.fbeExtra + this.Environment.fbeExtra + this.NotificationTTLInMinutes.fbeExtra + this.ResponseTimeoutInSeconds.fbeExtra + this.MaxBatchSize.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelNotificationServiceSettings}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelNotificationServiceSettings.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelNotificationServiceSettings}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 214
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelNotificationServiceSettings}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelNotificationServiceSettings}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Host.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Host.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Host.fbeSize

    if ((fbeCurrentSize + this.Port.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Port.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Port.fbeSize

    if ((fbeCurrentSize + this.UseSsl.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.UseSsl.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UseSsl.fbeSize

    if ((fbeCurrentSize + this.Environment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Environment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Environment.fbeSize

    if ((fbeCurrentSize + this.NotificationTTLInMinutes.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NotificationTTLInMinutes.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NotificationTTLInMinutes.fbeSize

    if ((fbeCurrentSize + this.ResponseTimeoutInSeconds.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ResponseTimeoutInSeconds.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ResponseTimeoutInSeconds.fbeSize

    if ((fbeCurrentSize + this.MaxBatchSize.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MaxBatchSize.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxBatchSize.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelNotificationServiceSettings}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelNotificationServiceSettings}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelNotificationServiceSettings}
   * @param {!NotificationServiceSettings} fbeValue Default value, defaults is new NotificationServiceSettings()
   * @returns {!NotificationServiceSettings} NotificationServiceSettings value
   */
  get (fbeValue = new NotificationServiceSettings()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelNotificationServiceSettings}
   * @param {!NotificationServiceSettings} fbeValue NotificationServiceSettings value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Host.fbeSize) <= fbeStructSize) {
      fbeValue.Host = this.Host.get()
    } else {
      fbeValue.Host = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Host.fbeSize

    if ((fbeCurrentSize + this.Port.fbeSize) <= fbeStructSize) {
      fbeValue.Port = this.Port.get()
    } else {
      fbeValue.Port = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Port.fbeSize

    if ((fbeCurrentSize + this.UseSsl.fbeSize) <= fbeStructSize) {
      fbeValue.UseSsl = this.UseSsl.get()
    } else {
      fbeValue.UseSsl = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UseSsl.fbeSize

    if ((fbeCurrentSize + this.Environment.fbeSize) <= fbeStructSize) {
      fbeValue.Environment = this.Environment.get()
    } else {
      fbeValue.Environment = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Environment.fbeSize

    if ((fbeCurrentSize + this.NotificationTTLInMinutes.fbeSize) <= fbeStructSize) {
      fbeValue.NotificationTTLInMinutes = this.NotificationTTLInMinutes.get(5)
    } else {
      fbeValue.NotificationTTLInMinutes = 5
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NotificationTTLInMinutes.fbeSize

    if ((fbeCurrentSize + this.ResponseTimeoutInSeconds.fbeSize) <= fbeStructSize) {
      fbeValue.ResponseTimeoutInSeconds = this.ResponseTimeoutInSeconds.get(new UInt64(30, 0))
    } else {
      fbeValue.ResponseTimeoutInSeconds = new UInt64(30, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ResponseTimeoutInSeconds.fbeSize

    if ((fbeCurrentSize + this.MaxBatchSize.fbeSize) <= fbeStructSize) {
      fbeValue.MaxBatchSize = this.MaxBatchSize.get(100)
    } else {
      fbeValue.MaxBatchSize = 100
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxBatchSize.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelNotificationServiceSettings}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelNotificationServiceSettings}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelNotificationServiceSettings}
   * @param {!NotificationServiceSettings} fbeValue NotificationServiceSettings value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelNotificationServiceSettings}
   * @param {NotificationServiceSettings} fbeValue NotificationServiceSettings value
   */
  setFields (fbeValue) {
    this.Host.set(fbeValue.Host)
    this.Port.set(fbeValue.Port)
    this.UseSsl.set(fbeValue.UseSsl)
    this.Environment.set(fbeValue.Environment)
    this.NotificationTTLInMinutes.set(fbeValue.NotificationTTLInMinutes)
    this.ResponseTimeoutInSeconds.set(fbeValue.ResponseTimeoutInSeconds)
    this.MaxBatchSize.set(fbeValue.MaxBatchSize)
  }
}

export { FieldModelNotificationServiceSettings };

/**
 * Fast Binary Encoding NotificationServiceSettings model
 */
class NotificationServiceSettingsModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelNotificationServiceSettings(this.buffer, 4)
  }

  /**
   * Get the NotificationServiceSettings model
   * @this {!NotificationServiceSettingsModel}
   * @returns {!FieldModelNotificationServiceSettings} model NotificationServiceSettings model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!NotificationServiceSettingsModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!NotificationServiceSettingsModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return NotificationServiceSettingsModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!NotificationServiceSettingsModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelNotificationServiceSettings.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!NotificationServiceSettingsModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!NotificationServiceSettingsModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!NotificationServiceSettingsModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!NotificationServiceSettingsModel}
   * @param {!NotificationServiceSettings} value NotificationServiceSettings value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!NotificationServiceSettingsModel}
   * @param {!NotificationServiceSettings} value NotificationServiceSettings value, defaults is new NotificationServiceSettings()
   * @return {!object} Deserialized NotificationServiceSettings value and its size
   */
  deserialize (value = new NotificationServiceSettings()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new NotificationServiceSettings(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new NotificationServiceSettings(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!NotificationServiceSettingsModel}
   * @param {!number} prev Previous NotificationServiceSettings model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { NotificationServiceSettingsModel };

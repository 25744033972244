import { Big } from '@api/fbe/big';
import { Message as FbeCoreMessage } from '@api/fbe/core/Message';
import { Int64, UInt64 } from '@api/fbe/int64';
import { UUID } from '@api/fbe/uuid';
import { baseSorter } from '@components/TableExt/RowFilters/utils';

// prettier-ignore
type OptionalPropertyOf<T> = Exclude<{
    // here we get union of all optional keys of T
    [K in keyof T]: T extends Record<K, T[K]> ? never : K;
}[keyof T], undefined>;

// prettier-ignore
type MakeOptional<T, OptionalKeys extends keyof T> =
    // here we make optional specified keys on T

    // omit optional fields from required object
    Omit<Required<T>, OptionalKeys> &

    // pick only optional fields from optional object
    Pick<Partial<T>, OptionalKeys>
;

// prettier-ignore
type ConvertSingleFbeType<T> =
    // Types from fbe's toJSON implementation

    // convert UUID to string
    T extends UUID ? string :
    // convert UInt64 to number
    T extends UInt64 ? number :
    // convert Int64 to number
    T extends Int64 ? number :
    // convert Big to number
    T extends Big ? number :
    // convert Uint8Array to base64 string
    T extends Uint8Array ? string :
    // !! enums are not converted !!
    // T extends _EnumBase ? number :
    // convert structs recursive
    T extends { fbeType: number } ? ConvertFbeObjType<T> :
    // convert Set to Array; !! Set's items is not converted !!
    T extends Set<infer SetT> ? SetT[] :
    // convert Map to Record
    T extends Map<any, infer MapVal> ? Record<string, MapVal> :
    // default
    T
;

// prettier-ignore
type RecoverOptionalNull<T, Key extends keyof T, NewFieldT> =
    // here we recover null type if there was originally
    T[Key] extends null ? NewFieldT | null : NewFieldT
;

type ExcludeFbeSpecificKeys = '__type_response' | '__has_response' | '__response_class' | 'fbeType' | 'toJSON';

// prettier-ignore
export type ConvertFbeObjType<T> = MakeOptional<{
    [x in Exclude<keyof T, ExcludeFbeSpecificKeys>]: RecoverOptionalNull<T, x, ConvertSingleFbeType<T[x]>>;
}, Exclude<OptionalPropertyOf<T>, ExcludeFbeSpecificKeys>>;

// Convert request type to Update value
export type FbeUpdatableType<RequestT, FromT = ConvertFbeObjType<RequestT>> = Omit<
    MakeOptional<FromT, OptionalPropertyOf<FromT>>,
    keyof FbeCoreMessage
>;

/** convert fbe's object to client side */
export function adaptApi<T>(apiObject: T): ConvertFbeObjType<T> {
    if (typeof (apiObject as any).toJSON !== 'function') {
        console.warn(`adaptApi: no toJSON field in`, { apiObject });
        return apiObject as any;
    }
    return (apiObject as any).toJSON() as any;
}

// prettier-ignore
export function toApi<
    T extends { fbeType: any },
    FromT = ConvertFbeObjType<T>
>(
    fbeClass: new (...args: any) => T,
    from: Omit<MakeOptional<FromT, OptionalPropertyOf<FromT>>, keyof FbeCoreMessage>,
): T {
    const fbeObj = new fbeClass;
    (fbeObj as any).copy(from);
    return fbeObj;
}

export const convertFbeEnumToSelectOptions = (
    fbeEnumClass,
    localEnum: {
        [x: number]: string;
    },
): Array<{ label: string; value: number }> => {
    const enumKeys = Object.keys(fbeEnumClass).filter((key) => localEnum[fbeEnumClass[key].valueOf()]);
    return enumKeys.map((key) => {
        const enumValue = fbeEnumClass[key].valueOf();
        if (localEnum[enumValue]) {
            return { label: localEnum[enumValue], value: enumValue };
        }
        return { label: `${key}`, value: enumValue };
    });
};

export const convertBitArrayToSelectValues = (bitArray: number[] | null, fbeEnumClass: any): number[] => {
    const enumKeys = Object.keys(fbeEnumClass)
        .map((key) => ({ key, value: fbeEnumClass[key].valueOf() }))
        .sort((a, b) => baseSorter(a.value, b.value));

    if (bitArray === null || enumKeys.length === 0) return [];
    const values = [...bitArray]
        .reverse()
        .map((item, index) => {
            if (item !== 0) {
                return enumKeys[index].value;
            }
            return null;
        })
        .filter((item) => item !== null);
    return values;
};

export const convertSelectValuesArrayToBitArray = (values: number[] | null, fbeEnumClass): number[] => {
    const enumKeys = Object.keys(fbeEnumClass)
        .map((key) => ({ key, value: fbeEnumClass[key].valueOf() }))
        .sort((a, b) => baseSorter(a.value, b.value))
        .reverse();

    const bitArray = ''
        .padStart(enumKeys.length, '0')
        .split('')
        .map((item) => +item);

    if (values === null || enumKeys.length === 0) return bitArray;

    values.forEach((item) => {
        const keyIndex = enumKeys.findIndex((key) => key.value === item);
        if (keyIndex !== -1) {
            bitArray[keyIndex] = 1;
        }
    });
    return bitArray;
};

export const getEnumKeysCount = (enumObj): number => {
    if (!enumObj) return 0;
    return Object.keys(enumObj).length;
};

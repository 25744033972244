
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Message} from '../core/Message';
import {FieldModelMessage} from '../core/Message';
import {AccountingType} from '../core/AccountingType';
import {FieldModelAccountingType} from '../core/AccountingType';
import {ReportsSubscriptionType} from '../core/ReportsSubscriptionType';
import {FieldModelReportsSubscriptionType} from '../core/ReportsSubscriptionType';
import {FeaturesToggleForce} from '../core/FeaturesToggleForce';
import {FieldModelFeaturesToggleForce} from '../core/FeaturesToggleForce';

/**
 * AccountCreateParams struct
 */
class AccountCreateParams extends Message {
  /**
   * Initialize struct
   * @param {!Message=} parent
   * @param {UUID=} accountGroupId
   * @param {string=} accountGroupName
   * @param {!string=} name
   * @param {!string=} country
   * @param {!string=} city
   * @param {!string=} state
   * @param {!string=} zipCode
   * @param {!string=} address
   * @param {!string=} phone
   * @param {!string=} email
   * @param {!string=} identifier
   * @param {!string=} status
   * @param {!string=} comment
   * @param {!string=} description
   * @param {boolean=} isEnabled
   * @param {boolean=} deprecated_IsOTPEnabled
   * @param {boolean=} isTradeEnabled
   * @param {string=} password
   * @param {string=} deprecated_InvestorPassword
   * @param {string=} phonePassword
   * @param {AccountingType=} accountingType
   * @param {number=} agentAccount
   * @param {number=} maxPendingOrders
   * @param {UUID=} balanceCurrencyId
   * @param {string=} balanceCurrencyName
   * @param {number=} balance
   * @param {number=} credit
   * @param {number=} leverage
   * @param {number=} maxLeverage
   * @param {ReportsSubscriptionType=} reportsSubscriptionType
   * @param {number=} maxOrders
   * @param {string=} externalClientId
   * @param {string=} externalAccountId
   * @param {!string=} localLanguageName
   * @param {FeaturesToggleForce=} featuresToggleForce
   * @param {number=} nextLoginInclusive
   * @param {number=} toLoginInclusive
   * @param {number=} disabledEventTypesMask
   * @param {string=} languageName
   * @constructor
   */
  constructor (parent = new Message(), accountGroupId = null, accountGroupName = null, name = '', country = '', city = '', state = '', zipCode = '', address = '', phone = '', email = '', identifier = '', status = '', comment = '', description = '', isEnabled = null, deprecated_IsOTPEnabled = null, isTradeEnabled = null, password = null, deprecated_InvestorPassword = null, phonePassword = null, accountingType = null, agentAccount = null, maxPendingOrders = null, balanceCurrencyId = null, balanceCurrencyName = null, balance = null, credit = null, leverage = null, maxLeverage = null, reportsSubscriptionType = null, maxOrders = null, externalClientId = null, externalAccountId = null, localLanguageName = '', featuresToggleForce = null, nextLoginInclusive = null, toLoginInclusive = null, disabledEventTypesMask = null, languageName = null) {
    super()
    super.copy(parent)
    this.AccountGroupId = accountGroupId
    this.AccountGroupName = accountGroupName
    this.Name = name
    this.Country = country
    this.City = city
    this.State = state
    this.ZipCode = zipCode
    this.Address = address
    this.Phone = phone
    this.Email = email
    this.Identifier = identifier
    this.Status = status
    this.Comment = comment
    this.Description = description
    this.IsEnabled = isEnabled
    this.Deprecated_IsOTPEnabled = deprecated_IsOTPEnabled
    this.IsTradeEnabled = isTradeEnabled
    this.Password = password
    this.Deprecated_InvestorPassword = deprecated_InvestorPassword
    this.PhonePassword = phonePassword
    this.AccountingType = accountingType
    this.AgentAccount = agentAccount
    this.MaxPendingOrders = maxPendingOrders
    this.BalanceCurrencyId = balanceCurrencyId
    this.BalanceCurrencyName = balanceCurrencyName
    this.Balance = balance
    this.Credit = credit
    this.Leverage = leverage
    this.MaxLeverage = maxLeverage
    this.ReportsSubscriptionType = reportsSubscriptionType
    this.MaxOrders = maxOrders
    this.ExternalClientId = externalClientId
    this.ExternalAccountId = externalAccountId
    this.LocalLanguageName = localLanguageName
    this.FeaturesToggleForce = featuresToggleForce
    this.NextLoginInclusive = nextLoginInclusive
    this.ToLoginInclusive = toLoginInclusive
    this.DisabledEventTypesMask = disabledEventTypesMask
    this.LanguageName = languageName
  }

  /**
   * Copy struct (shallow copy)
   * @this {!AccountCreateParams}
   * @param {!AccountCreateParams} other Other struct
   * @returns {!AccountCreateParams} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.AccountGroupId != null) {
      this.AccountGroupId = new UUID(other.AccountGroupId)
    } else {
      this.AccountGroupId = null
    }
    if (other.AccountGroupName != null) {
      this.AccountGroupName = other.AccountGroupName
    } else {
      this.AccountGroupName = null
    }
    if (other.Name != null) {
      this.Name = other.Name
    } else {
      this.Name = null
    }
    if (other.Country != null) {
      this.Country = other.Country
    } else {
      this.Country = null
    }
    if (other.City != null) {
      this.City = other.City
    } else {
      this.City = null
    }
    if (other.State != null) {
      this.State = other.State
    } else {
      this.State = null
    }
    if (other.ZipCode != null) {
      this.ZipCode = other.ZipCode
    } else {
      this.ZipCode = null
    }
    if (other.Address != null) {
      this.Address = other.Address
    } else {
      this.Address = null
    }
    if (other.Phone != null) {
      this.Phone = other.Phone
    } else {
      this.Phone = null
    }
    if (other.Email != null) {
      this.Email = other.Email
    } else {
      this.Email = null
    }
    if (other.Identifier != null) {
      this.Identifier = other.Identifier
    } else {
      this.Identifier = null
    }
    if (other.Status != null) {
      this.Status = other.Status
    } else {
      this.Status = null
    }
    if (other.Comment != null) {
      this.Comment = other.Comment
    } else {
      this.Comment = null
    }
    if (other.Description != null) {
      this.Description = other.Description
    } else {
      this.Description = null
    }
    if (other.IsEnabled != null) {
      this.IsEnabled = other.IsEnabled
    } else {
      this.IsEnabled = null
    }
    if (other.Deprecated_IsOTPEnabled != null) {
      this.Deprecated_IsOTPEnabled = other.Deprecated_IsOTPEnabled
    } else {
      this.Deprecated_IsOTPEnabled = null
    }
    if (other.IsTradeEnabled != null) {
      this.IsTradeEnabled = other.IsTradeEnabled
    } else {
      this.IsTradeEnabled = null
    }
    if (other.Password != null) {
      this.Password = other.Password
    } else {
      this.Password = null
    }
    if (other.Deprecated_InvestorPassword != null) {
      this.Deprecated_InvestorPassword = other.Deprecated_InvestorPassword
    } else {
      this.Deprecated_InvestorPassword = null
    }
    if (other.PhonePassword != null) {
      this.PhonePassword = other.PhonePassword
    } else {
      this.PhonePassword = null
    }
    if (other.AccountingType != null) {
      this.AccountingType = AccountingType.fromObject(other.AccountingType)
    } else {
      this.AccountingType = null
    }
    if (other.AgentAccount != null) {
      this.AgentAccount = UInt64.fromNumber(other.AgentAccount)
    } else {
      this.AgentAccount = null
    }
    if (other.MaxPendingOrders != null) {
      this.MaxPendingOrders = UInt64.fromNumber(other.MaxPendingOrders)
    } else {
      this.MaxPendingOrders = null
    }
    if (other.BalanceCurrencyId != null) {
      this.BalanceCurrencyId = new UUID(other.BalanceCurrencyId)
    } else {
      this.BalanceCurrencyId = null
    }
    if (other.BalanceCurrencyName != null) {
      this.BalanceCurrencyName = other.BalanceCurrencyName
    } else {
      this.BalanceCurrencyName = null
    }
    if (other.Balance != null) {
      this.Balance = other.Balance
    } else {
      this.Balance = null
    }
    if (other.Credit != null) {
      this.Credit = other.Credit
    } else {
      this.Credit = null
    }
    if (other.Leverage != null) {
      this.Leverage = other.Leverage
    } else {
      this.Leverage = null
    }
    if (other.MaxLeverage != null) {
      this.MaxLeverage = other.MaxLeverage
    } else {
      this.MaxLeverage = null
    }
    if (other.ReportsSubscriptionType != null) {
      this.ReportsSubscriptionType = ReportsSubscriptionType.fromObject(other.ReportsSubscriptionType)
    } else {
      this.ReportsSubscriptionType = null
    }
    if (other.MaxOrders != null) {
      this.MaxOrders = UInt64.fromNumber(other.MaxOrders)
    } else {
      this.MaxOrders = null
    }
    if (other.ExternalClientId != null) {
      this.ExternalClientId = other.ExternalClientId
    } else {
      this.ExternalClientId = null
    }
    if (other.ExternalAccountId != null) {
      this.ExternalAccountId = other.ExternalAccountId
    } else {
      this.ExternalAccountId = null
    }
    if (other.LocalLanguageName != null) {
      this.LocalLanguageName = other.LocalLanguageName
    } else {
      this.LocalLanguageName = null
    }
    if (other.FeaturesToggleForce != null) {
      this.FeaturesToggleForce = FeaturesToggleForce.fromObject(other.FeaturesToggleForce)
    } else {
      this.FeaturesToggleForce = null
    }
    if (other.NextLoginInclusive != null) {
      this.NextLoginInclusive = UInt64.fromNumber(other.NextLoginInclusive)
    } else {
      this.NextLoginInclusive = null
    }
    if (other.ToLoginInclusive != null) {
      this.ToLoginInclusive = UInt64.fromNumber(other.ToLoginInclusive)
    } else {
      this.ToLoginInclusive = null
    }
    if (other.DisabledEventTypesMask != null) {
      this.DisabledEventTypesMask = UInt64.fromNumber(other.DisabledEventTypesMask)
    } else {
      this.DisabledEventTypesMask = null
    }
    if (other.LanguageName != null) {
      this.LanguageName = other.LanguageName
    } else {
      this.LanguageName = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!AccountCreateParams}
   * @returns {!AccountCreateParams} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new AccountCreateParamsModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new AccountCreateParamsModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!AccountCreateParams}
   * @param {!AccountCreateParams} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof AccountCreateParams)) {
      throw new TypeError('Instance of AccountCreateParams is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!AccountCreateParams}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      AccountGroupId: ((this.AccountGroupId != null) ? this.AccountGroupId.toString() : null),
      AccountGroupName: ((this.AccountGroupName != null) ? this.AccountGroupName : null),
      Name: ((this.Name != null) ? this.Name : null),
      Country: ((this.Country != null) ? this.Country : null),
      City: ((this.City != null) ? this.City : null),
      State: ((this.State != null) ? this.State : null),
      ZipCode: ((this.ZipCode != null) ? this.ZipCode : null),
      Address: ((this.Address != null) ? this.Address : null),
      Phone: ((this.Phone != null) ? this.Phone : null),
      Email: ((this.Email != null) ? this.Email : null),
      Identifier: ((this.Identifier != null) ? this.Identifier : null),
      Status: ((this.Status != null) ? this.Status : null),
      Comment: ((this.Comment != null) ? this.Comment : null),
      Description: ((this.Description != null) ? this.Description : null),
      IsEnabled: ((this.IsEnabled != null) ? this.IsEnabled : null),
      Deprecated_IsOTPEnabled: ((this.Deprecated_IsOTPEnabled != null) ? this.Deprecated_IsOTPEnabled : null),
      IsTradeEnabled: ((this.IsTradeEnabled != null) ? this.IsTradeEnabled : null),
      Password: ((this.Password != null) ? this.Password : null),
      Deprecated_InvestorPassword: ((this.Deprecated_InvestorPassword != null) ? this.Deprecated_InvestorPassword : null),
      PhonePassword: ((this.PhonePassword != null) ? this.PhonePassword : null),
      AccountingType: ((this.AccountingType != null) ? this.AccountingType : null),
      AgentAccount: ((this.AgentAccount != null) ? this.AgentAccount.toNumber() : null),
      MaxPendingOrders: ((this.MaxPendingOrders != null) ? this.MaxPendingOrders.toNumber() : null),
      BalanceCurrencyId: ((this.BalanceCurrencyId != null) ? this.BalanceCurrencyId.toString() : null),
      BalanceCurrencyName: ((this.BalanceCurrencyName != null) ? this.BalanceCurrencyName : null),
      Balance: ((this.Balance != null) ? this.Balance : null),
      Credit: ((this.Credit != null) ? this.Credit : null),
      Leverage: ((this.Leverage != null) ? this.Leverage : null),
      MaxLeverage: ((this.MaxLeverage != null) ? this.MaxLeverage : null),
      ReportsSubscriptionType: ((this.ReportsSubscriptionType != null) ? this.ReportsSubscriptionType : null),
      MaxOrders: ((this.MaxOrders != null) ? this.MaxOrders.toNumber() : null),
      ExternalClientId: ((this.ExternalClientId != null) ? this.ExternalClientId : null),
      ExternalAccountId: ((this.ExternalAccountId != null) ? this.ExternalAccountId : null),
      LocalLanguageName: ((this.LocalLanguageName != null) ? this.LocalLanguageName : null),
      FeaturesToggleForce: ((this.FeaturesToggleForce != null) ? this.FeaturesToggleForce : null),
      NextLoginInclusive: ((this.NextLoginInclusive != null) ? this.NextLoginInclusive.toNumber() : null),
      ToLoginInclusive: ((this.ToLoginInclusive != null) ? this.ToLoginInclusive.toNumber() : null),
      DisabledEventTypesMask: ((this.DisabledEventTypesMask != null) ? this.DisabledEventTypesMask.toNumber() : null),
      LanguageName: ((this.LanguageName != null) ? this.LanguageName : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return AccountCreateParams.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!AccountCreateParams} other Object value
   * @returns {!AccountCreateParams} Created struct
   */
  static fromObject (other) {
    return new AccountCreateParams().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!AccountCreateParams}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return AccountCreateParams.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!AccountCreateParams}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6477
  }
}

export { AccountCreateParams };

/**
 * Fast Binary Encoding AccountCreateParams field model
 */
class FieldModelAccountCreateParams extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelMessage(buffer, 4 + 4)
    this.AccountGroupId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.AccountGroupName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.AccountGroupId.fbeOffset + this.AccountGroupId.fbeSize), buffer, this.AccountGroupId.fbeOffset + this.AccountGroupId.fbeSize)
    this.Name = new fbe.FieldModelString(buffer, this.AccountGroupName.fbeOffset + this.AccountGroupName.fbeSize)
    this.Country = new fbe.FieldModelString(buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.City = new fbe.FieldModelString(buffer, this.Country.fbeOffset + this.Country.fbeSize)
    this.State = new fbe.FieldModelString(buffer, this.City.fbeOffset + this.City.fbeSize)
    this.ZipCode = new fbe.FieldModelString(buffer, this.State.fbeOffset + this.State.fbeSize)
    this.Address = new fbe.FieldModelString(buffer, this.ZipCode.fbeOffset + this.ZipCode.fbeSize)
    this.Phone = new fbe.FieldModelString(buffer, this.Address.fbeOffset + this.Address.fbeSize)
    this.Email = new fbe.FieldModelString(buffer, this.Phone.fbeOffset + this.Phone.fbeSize)
    this.Identifier = new fbe.FieldModelString(buffer, this.Email.fbeOffset + this.Email.fbeSize)
    this.Status = new fbe.FieldModelString(buffer, this.Identifier.fbeOffset + this.Identifier.fbeSize)
    this.Comment = new fbe.FieldModelString(buffer, this.Status.fbeOffset + this.Status.fbeSize)
    this.Description = new fbe.FieldModelString(buffer, this.Comment.fbeOffset + this.Comment.fbeSize)
    this.IsEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.Description.fbeOffset + this.Description.fbeSize), buffer, this.Description.fbeOffset + this.Description.fbeSize)
    this.Deprecated_IsOTPEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.IsEnabled.fbeOffset + this.IsEnabled.fbeSize), buffer, this.IsEnabled.fbeOffset + this.IsEnabled.fbeSize)
    this.IsTradeEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.Deprecated_IsOTPEnabled.fbeOffset + this.Deprecated_IsOTPEnabled.fbeSize), buffer, this.Deprecated_IsOTPEnabled.fbeOffset + this.Deprecated_IsOTPEnabled.fbeSize)
    this.Password = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.IsTradeEnabled.fbeOffset + this.IsTradeEnabled.fbeSize), buffer, this.IsTradeEnabled.fbeOffset + this.IsTradeEnabled.fbeSize)
    this.Deprecated_InvestorPassword = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.Password.fbeOffset + this.Password.fbeSize), buffer, this.Password.fbeOffset + this.Password.fbeSize)
    this.PhonePassword = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.Deprecated_InvestorPassword.fbeOffset + this.Deprecated_InvestorPassword.fbeSize), buffer, this.Deprecated_InvestorPassword.fbeOffset + this.Deprecated_InvestorPassword.fbeSize)
    this.AccountingType = new fbe.FieldModelOptional(new FieldModelAccountingType(buffer, this.PhonePassword.fbeOffset + this.PhonePassword.fbeSize), buffer, this.PhonePassword.fbeOffset + this.PhonePassword.fbeSize)
    this.AgentAccount = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.AccountingType.fbeOffset + this.AccountingType.fbeSize), buffer, this.AccountingType.fbeOffset + this.AccountingType.fbeSize)
    this.MaxPendingOrders = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.AgentAccount.fbeOffset + this.AgentAccount.fbeSize), buffer, this.AgentAccount.fbeOffset + this.AgentAccount.fbeSize)
    this.BalanceCurrencyId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.MaxPendingOrders.fbeOffset + this.MaxPendingOrders.fbeSize), buffer, this.MaxPendingOrders.fbeOffset + this.MaxPendingOrders.fbeSize)
    this.BalanceCurrencyName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.BalanceCurrencyId.fbeOffset + this.BalanceCurrencyId.fbeSize), buffer, this.BalanceCurrencyId.fbeOffset + this.BalanceCurrencyId.fbeSize)
    this.Balance = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.BalanceCurrencyName.fbeOffset + this.BalanceCurrencyName.fbeSize), buffer, this.BalanceCurrencyName.fbeOffset + this.BalanceCurrencyName.fbeSize)
    this.Credit = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.Balance.fbeOffset + this.Balance.fbeSize), buffer, this.Balance.fbeOffset + this.Balance.fbeSize)
    this.Leverage = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.Credit.fbeOffset + this.Credit.fbeSize), buffer, this.Credit.fbeOffset + this.Credit.fbeSize)
    this.MaxLeverage = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.Leverage.fbeOffset + this.Leverage.fbeSize), buffer, this.Leverage.fbeOffset + this.Leverage.fbeSize)
    this.ReportsSubscriptionType = new fbe.FieldModelOptional(new FieldModelReportsSubscriptionType(buffer, this.MaxLeverage.fbeOffset + this.MaxLeverage.fbeSize), buffer, this.MaxLeverage.fbeOffset + this.MaxLeverage.fbeSize)
    this.MaxOrders = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.ReportsSubscriptionType.fbeOffset + this.ReportsSubscriptionType.fbeSize), buffer, this.ReportsSubscriptionType.fbeOffset + this.ReportsSubscriptionType.fbeSize)
    this.ExternalClientId = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.MaxOrders.fbeOffset + this.MaxOrders.fbeSize), buffer, this.MaxOrders.fbeOffset + this.MaxOrders.fbeSize)
    this.ExternalAccountId = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.ExternalClientId.fbeOffset + this.ExternalClientId.fbeSize), buffer, this.ExternalClientId.fbeOffset + this.ExternalClientId.fbeSize)
    this.LocalLanguageName = new fbe.FieldModelString(buffer, this.ExternalAccountId.fbeOffset + this.ExternalAccountId.fbeSize)
    this.FeaturesToggleForce = new fbe.FieldModelOptional(new FieldModelFeaturesToggleForce(buffer, this.LocalLanguageName.fbeOffset + this.LocalLanguageName.fbeSize), buffer, this.LocalLanguageName.fbeOffset + this.LocalLanguageName.fbeSize)
    this.NextLoginInclusive = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.FeaturesToggleForce.fbeOffset + this.FeaturesToggleForce.fbeSize), buffer, this.FeaturesToggleForce.fbeOffset + this.FeaturesToggleForce.fbeSize)
    this.ToLoginInclusive = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.NextLoginInclusive.fbeOffset + this.NextLoginInclusive.fbeSize), buffer, this.NextLoginInclusive.fbeOffset + this.NextLoginInclusive.fbeSize)
    this.DisabledEventTypesMask = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.ToLoginInclusive.fbeOffset + this.ToLoginInclusive.fbeSize), buffer, this.ToLoginInclusive.fbeOffset + this.ToLoginInclusive.fbeSize)
    this.LanguageName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.DisabledEventTypesMask.fbeOffset + this.DisabledEventTypesMask.fbeSize), buffer, this.DisabledEventTypesMask.fbeOffset + this.DisabledEventTypesMask.fbeSize)
  }

  /**
   * Get the core.Message field model
   * @this {!FieldModelAccountCreateParams}
   * @returns {!FieldModelMessage} core.Message field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelAccountCreateParams}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelAccountCreateParams}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.AccountGroupId.fbeSize + this.AccountGroupName.fbeSize + this.Name.fbeSize + this.Country.fbeSize + this.City.fbeSize + this.State.fbeSize + this.ZipCode.fbeSize + this.Address.fbeSize + this.Phone.fbeSize + this.Email.fbeSize + this.Identifier.fbeSize + this.Status.fbeSize + this.Comment.fbeSize + this.Description.fbeSize + this.IsEnabled.fbeSize + this.Deprecated_IsOTPEnabled.fbeSize + this.IsTradeEnabled.fbeSize + this.Password.fbeSize + this.Deprecated_InvestorPassword.fbeSize + this.PhonePassword.fbeSize + this.AccountingType.fbeSize + this.AgentAccount.fbeSize + this.MaxPendingOrders.fbeSize + this.BalanceCurrencyId.fbeSize + this.BalanceCurrencyName.fbeSize + this.Balance.fbeSize + this.Credit.fbeSize + this.Leverage.fbeSize + this.MaxLeverage.fbeSize + this.ReportsSubscriptionType.fbeSize + this.MaxOrders.fbeSize + this.ExternalClientId.fbeSize + this.ExternalAccountId.fbeSize + this.LocalLanguageName.fbeSize + this.FeaturesToggleForce.fbeSize + this.NextLoginInclusive.fbeSize + this.ToLoginInclusive.fbeSize + this.DisabledEventTypesMask.fbeSize + this.LanguageName.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelAccountCreateParams}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.AccountGroupId.fbeExtra + this.AccountGroupName.fbeExtra + this.Name.fbeExtra + this.Country.fbeExtra + this.City.fbeExtra + this.State.fbeExtra + this.ZipCode.fbeExtra + this.Address.fbeExtra + this.Phone.fbeExtra + this.Email.fbeExtra + this.Identifier.fbeExtra + this.Status.fbeExtra + this.Comment.fbeExtra + this.Description.fbeExtra + this.IsEnabled.fbeExtra + this.Deprecated_IsOTPEnabled.fbeExtra + this.IsTradeEnabled.fbeExtra + this.Password.fbeExtra + this.Deprecated_InvestorPassword.fbeExtra + this.PhonePassword.fbeExtra + this.AccountingType.fbeExtra + this.AgentAccount.fbeExtra + this.MaxPendingOrders.fbeExtra + this.BalanceCurrencyId.fbeExtra + this.BalanceCurrencyName.fbeExtra + this.Balance.fbeExtra + this.Credit.fbeExtra + this.Leverage.fbeExtra + this.MaxLeverage.fbeExtra + this.ReportsSubscriptionType.fbeExtra + this.MaxOrders.fbeExtra + this.ExternalClientId.fbeExtra + this.ExternalAccountId.fbeExtra + this.LocalLanguageName.fbeExtra + this.FeaturesToggleForce.fbeExtra + this.NextLoginInclusive.fbeExtra + this.ToLoginInclusive.fbeExtra + this.DisabledEventTypesMask.fbeExtra + this.LanguageName.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelAccountCreateParams}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelAccountCreateParams.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelAccountCreateParams}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6477
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelAccountCreateParams}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelAccountCreateParams}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.AccountGroupId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountGroupId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupId.fbeSize

    if ((fbeCurrentSize + this.AccountGroupName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountGroupName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupName.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Country.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Country.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Country.fbeSize

    if ((fbeCurrentSize + this.City.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.City.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.City.fbeSize

    if ((fbeCurrentSize + this.State.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.State.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.State.fbeSize

    if ((fbeCurrentSize + this.ZipCode.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ZipCode.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ZipCode.fbeSize

    if ((fbeCurrentSize + this.Address.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Address.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Address.fbeSize

    if ((fbeCurrentSize + this.Phone.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Phone.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Phone.fbeSize

    if ((fbeCurrentSize + this.Email.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Email.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Email.fbeSize

    if ((fbeCurrentSize + this.Identifier.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Identifier.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Identifier.fbeSize

    if ((fbeCurrentSize + this.Status.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Status.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Status.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Comment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Description.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.Deprecated_IsOTPEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deprecated_IsOTPEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated_IsOTPEnabled.fbeSize

    if ((fbeCurrentSize + this.IsTradeEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsTradeEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsTradeEnabled.fbeSize

    if ((fbeCurrentSize + this.Password.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Password.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Password.fbeSize

    if ((fbeCurrentSize + this.Deprecated_InvestorPassword.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deprecated_InvestorPassword.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated_InvestorPassword.fbeSize

    if ((fbeCurrentSize + this.PhonePassword.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PhonePassword.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PhonePassword.fbeSize

    if ((fbeCurrentSize + this.AccountingType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountingType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountingType.fbeSize

    if ((fbeCurrentSize + this.AgentAccount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AgentAccount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AgentAccount.fbeSize

    if ((fbeCurrentSize + this.MaxPendingOrders.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MaxPendingOrders.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxPendingOrders.fbeSize

    if ((fbeCurrentSize + this.BalanceCurrencyId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.BalanceCurrencyId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BalanceCurrencyId.fbeSize

    if ((fbeCurrentSize + this.BalanceCurrencyName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.BalanceCurrencyName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BalanceCurrencyName.fbeSize

    if ((fbeCurrentSize + this.Balance.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Balance.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Balance.fbeSize

    if ((fbeCurrentSize + this.Credit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Credit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Credit.fbeSize

    if ((fbeCurrentSize + this.Leverage.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Leverage.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Leverage.fbeSize

    if ((fbeCurrentSize + this.MaxLeverage.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MaxLeverage.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxLeverage.fbeSize

    if ((fbeCurrentSize + this.ReportsSubscriptionType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ReportsSubscriptionType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ReportsSubscriptionType.fbeSize

    if ((fbeCurrentSize + this.MaxOrders.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MaxOrders.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxOrders.fbeSize

    if ((fbeCurrentSize + this.ExternalClientId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ExternalClientId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalClientId.fbeSize

    if ((fbeCurrentSize + this.ExternalAccountId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ExternalAccountId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalAccountId.fbeSize

    if ((fbeCurrentSize + this.LocalLanguageName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LocalLanguageName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LocalLanguageName.fbeSize

    if ((fbeCurrentSize + this.FeaturesToggleForce.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.FeaturesToggleForce.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FeaturesToggleForce.fbeSize

    if ((fbeCurrentSize + this.NextLoginInclusive.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NextLoginInclusive.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NextLoginInclusive.fbeSize

    if ((fbeCurrentSize + this.ToLoginInclusive.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ToLoginInclusive.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ToLoginInclusive.fbeSize

    if ((fbeCurrentSize + this.DisabledEventTypesMask.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DisabledEventTypesMask.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DisabledEventTypesMask.fbeSize

    if ((fbeCurrentSize + this.LanguageName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LanguageName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LanguageName.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelAccountCreateParams}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelAccountCreateParams}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelAccountCreateParams}
   * @param {!AccountCreateParams} fbeValue Default value, defaults is new AccountCreateParams()
   * @returns {!AccountCreateParams} AccountCreateParams value
   */
  get (fbeValue = new AccountCreateParams()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelAccountCreateParams}
   * @param {!AccountCreateParams} fbeValue AccountCreateParams value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.AccountGroupId.fbeSize) <= fbeStructSize) {
      fbeValue.AccountGroupId = this.AccountGroupId.get()
    } else {
      fbeValue.AccountGroupId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupId.fbeSize

    if ((fbeCurrentSize + this.AccountGroupName.fbeSize) <= fbeStructSize) {
      fbeValue.AccountGroupName = this.AccountGroupName.get()
    } else {
      fbeValue.AccountGroupName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupName.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Country.fbeSize) <= fbeStructSize) {
      fbeValue.Country = this.Country.get()
    } else {
      fbeValue.Country = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Country.fbeSize

    if ((fbeCurrentSize + this.City.fbeSize) <= fbeStructSize) {
      fbeValue.City = this.City.get()
    } else {
      fbeValue.City = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.City.fbeSize

    if ((fbeCurrentSize + this.State.fbeSize) <= fbeStructSize) {
      fbeValue.State = this.State.get()
    } else {
      fbeValue.State = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.State.fbeSize

    if ((fbeCurrentSize + this.ZipCode.fbeSize) <= fbeStructSize) {
      fbeValue.ZipCode = this.ZipCode.get()
    } else {
      fbeValue.ZipCode = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ZipCode.fbeSize

    if ((fbeCurrentSize + this.Address.fbeSize) <= fbeStructSize) {
      fbeValue.Address = this.Address.get()
    } else {
      fbeValue.Address = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Address.fbeSize

    if ((fbeCurrentSize + this.Phone.fbeSize) <= fbeStructSize) {
      fbeValue.Phone = this.Phone.get()
    } else {
      fbeValue.Phone = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Phone.fbeSize

    if ((fbeCurrentSize + this.Email.fbeSize) <= fbeStructSize) {
      fbeValue.Email = this.Email.get()
    } else {
      fbeValue.Email = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Email.fbeSize

    if ((fbeCurrentSize + this.Identifier.fbeSize) <= fbeStructSize) {
      fbeValue.Identifier = this.Identifier.get()
    } else {
      fbeValue.Identifier = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Identifier.fbeSize

    if ((fbeCurrentSize + this.Status.fbeSize) <= fbeStructSize) {
      fbeValue.Status = this.Status.get()
    } else {
      fbeValue.Status = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Status.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) <= fbeStructSize) {
      fbeValue.Comment = this.Comment.get()
    } else {
      fbeValue.Comment = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) <= fbeStructSize) {
      fbeValue.Description = this.Description.get()
    } else {
      fbeValue.Description = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsEnabled = this.IsEnabled.get()
    } else {
      fbeValue.IsEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.Deprecated_IsOTPEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.Deprecated_IsOTPEnabled = this.Deprecated_IsOTPEnabled.get()
    } else {
      fbeValue.Deprecated_IsOTPEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated_IsOTPEnabled.fbeSize

    if ((fbeCurrentSize + this.IsTradeEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsTradeEnabled = this.IsTradeEnabled.get()
    } else {
      fbeValue.IsTradeEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsTradeEnabled.fbeSize

    if ((fbeCurrentSize + this.Password.fbeSize) <= fbeStructSize) {
      fbeValue.Password = this.Password.get()
    } else {
      fbeValue.Password = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Password.fbeSize

    if ((fbeCurrentSize + this.Deprecated_InvestorPassword.fbeSize) <= fbeStructSize) {
      fbeValue.Deprecated_InvestorPassword = this.Deprecated_InvestorPassword.get()
    } else {
      fbeValue.Deprecated_InvestorPassword = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated_InvestorPassword.fbeSize

    if ((fbeCurrentSize + this.PhonePassword.fbeSize) <= fbeStructSize) {
      fbeValue.PhonePassword = this.PhonePassword.get()
    } else {
      fbeValue.PhonePassword = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PhonePassword.fbeSize

    if ((fbeCurrentSize + this.AccountingType.fbeSize) <= fbeStructSize) {
      fbeValue.AccountingType = this.AccountingType.get()
    } else {
      fbeValue.AccountingType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountingType.fbeSize

    if ((fbeCurrentSize + this.AgentAccount.fbeSize) <= fbeStructSize) {
      fbeValue.AgentAccount = this.AgentAccount.get()
    } else {
      fbeValue.AgentAccount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AgentAccount.fbeSize

    if ((fbeCurrentSize + this.MaxPendingOrders.fbeSize) <= fbeStructSize) {
      fbeValue.MaxPendingOrders = this.MaxPendingOrders.get()
    } else {
      fbeValue.MaxPendingOrders = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxPendingOrders.fbeSize

    if ((fbeCurrentSize + this.BalanceCurrencyId.fbeSize) <= fbeStructSize) {
      fbeValue.BalanceCurrencyId = this.BalanceCurrencyId.get()
    } else {
      fbeValue.BalanceCurrencyId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BalanceCurrencyId.fbeSize

    if ((fbeCurrentSize + this.BalanceCurrencyName.fbeSize) <= fbeStructSize) {
      fbeValue.BalanceCurrencyName = this.BalanceCurrencyName.get()
    } else {
      fbeValue.BalanceCurrencyName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BalanceCurrencyName.fbeSize

    if ((fbeCurrentSize + this.Balance.fbeSize) <= fbeStructSize) {
      fbeValue.Balance = this.Balance.get()
    } else {
      fbeValue.Balance = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Balance.fbeSize

    if ((fbeCurrentSize + this.Credit.fbeSize) <= fbeStructSize) {
      fbeValue.Credit = this.Credit.get()
    } else {
      fbeValue.Credit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Credit.fbeSize

    if ((fbeCurrentSize + this.Leverage.fbeSize) <= fbeStructSize) {
      fbeValue.Leverage = this.Leverage.get()
    } else {
      fbeValue.Leverage = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Leverage.fbeSize

    if ((fbeCurrentSize + this.MaxLeverage.fbeSize) <= fbeStructSize) {
      fbeValue.MaxLeverage = this.MaxLeverage.get()
    } else {
      fbeValue.MaxLeverage = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxLeverage.fbeSize

    if ((fbeCurrentSize + this.ReportsSubscriptionType.fbeSize) <= fbeStructSize) {
      fbeValue.ReportsSubscriptionType = this.ReportsSubscriptionType.get()
    } else {
      fbeValue.ReportsSubscriptionType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ReportsSubscriptionType.fbeSize

    if ((fbeCurrentSize + this.MaxOrders.fbeSize) <= fbeStructSize) {
      fbeValue.MaxOrders = this.MaxOrders.get()
    } else {
      fbeValue.MaxOrders = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxOrders.fbeSize

    if ((fbeCurrentSize + this.ExternalClientId.fbeSize) <= fbeStructSize) {
      fbeValue.ExternalClientId = this.ExternalClientId.get()
    } else {
      fbeValue.ExternalClientId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalClientId.fbeSize

    if ((fbeCurrentSize + this.ExternalAccountId.fbeSize) <= fbeStructSize) {
      fbeValue.ExternalAccountId = this.ExternalAccountId.get()
    } else {
      fbeValue.ExternalAccountId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalAccountId.fbeSize

    if ((fbeCurrentSize + this.LocalLanguageName.fbeSize) <= fbeStructSize) {
      fbeValue.LocalLanguageName = this.LocalLanguageName.get()
    } else {
      fbeValue.LocalLanguageName = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LocalLanguageName.fbeSize

    if ((fbeCurrentSize + this.FeaturesToggleForce.fbeSize) <= fbeStructSize) {
      fbeValue.FeaturesToggleForce = this.FeaturesToggleForce.get()
    } else {
      fbeValue.FeaturesToggleForce = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FeaturesToggleForce.fbeSize

    if ((fbeCurrentSize + this.NextLoginInclusive.fbeSize) <= fbeStructSize) {
      fbeValue.NextLoginInclusive = this.NextLoginInclusive.get()
    } else {
      fbeValue.NextLoginInclusive = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NextLoginInclusive.fbeSize

    if ((fbeCurrentSize + this.ToLoginInclusive.fbeSize) <= fbeStructSize) {
      fbeValue.ToLoginInclusive = this.ToLoginInclusive.get()
    } else {
      fbeValue.ToLoginInclusive = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ToLoginInclusive.fbeSize

    if ((fbeCurrentSize + this.DisabledEventTypesMask.fbeSize) <= fbeStructSize) {
      fbeValue.DisabledEventTypesMask = this.DisabledEventTypesMask.get()
    } else {
      fbeValue.DisabledEventTypesMask = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DisabledEventTypesMask.fbeSize

    if ((fbeCurrentSize + this.LanguageName.fbeSize) <= fbeStructSize) {
      fbeValue.LanguageName = this.LanguageName.get()
    } else {
      fbeValue.LanguageName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LanguageName.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelAccountCreateParams}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelAccountCreateParams}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelAccountCreateParams}
   * @param {!AccountCreateParams} fbeValue AccountCreateParams value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelAccountCreateParams}
   * @param {AccountCreateParams} fbeValue AccountCreateParams value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.AccountGroupId.set(fbeValue.AccountGroupId)
    this.AccountGroupName.set(fbeValue.AccountGroupName)
    this.Name.set(fbeValue.Name)
    this.Country.set(fbeValue.Country)
    this.City.set(fbeValue.City)
    this.State.set(fbeValue.State)
    this.ZipCode.set(fbeValue.ZipCode)
    this.Address.set(fbeValue.Address)
    this.Phone.set(fbeValue.Phone)
    this.Email.set(fbeValue.Email)
    this.Identifier.set(fbeValue.Identifier)
    this.Status.set(fbeValue.Status)
    this.Comment.set(fbeValue.Comment)
    this.Description.set(fbeValue.Description)
    this.IsEnabled.set(fbeValue.IsEnabled)
    this.Deprecated_IsOTPEnabled.set(fbeValue.Deprecated_IsOTPEnabled)
    this.IsTradeEnabled.set(fbeValue.IsTradeEnabled)
    this.Password.set(fbeValue.Password)
    this.Deprecated_InvestorPassword.set(fbeValue.Deprecated_InvestorPassword)
    this.PhonePassword.set(fbeValue.PhonePassword)
    this.AccountingType.set(fbeValue.AccountingType)
    this.AgentAccount.set(fbeValue.AgentAccount)
    this.MaxPendingOrders.set(fbeValue.MaxPendingOrders)
    this.BalanceCurrencyId.set(fbeValue.BalanceCurrencyId)
    this.BalanceCurrencyName.set(fbeValue.BalanceCurrencyName)
    this.Balance.set(fbeValue.Balance)
    this.Credit.set(fbeValue.Credit)
    this.Leverage.set(fbeValue.Leverage)
    this.MaxLeverage.set(fbeValue.MaxLeverage)
    this.ReportsSubscriptionType.set(fbeValue.ReportsSubscriptionType)
    this.MaxOrders.set(fbeValue.MaxOrders)
    this.ExternalClientId.set(fbeValue.ExternalClientId)
    this.ExternalAccountId.set(fbeValue.ExternalAccountId)
    this.LocalLanguageName.set(fbeValue.LocalLanguageName)
    this.FeaturesToggleForce.set(fbeValue.FeaturesToggleForce)
    this.NextLoginInclusive.set(fbeValue.NextLoginInclusive)
    this.ToLoginInclusive.set(fbeValue.ToLoginInclusive)
    this.DisabledEventTypesMask.set(fbeValue.DisabledEventTypesMask)
    this.LanguageName.set(fbeValue.LanguageName)
  }
}

export { FieldModelAccountCreateParams };

/**
 * Fast Binary Encoding AccountCreateParams model
 */
class AccountCreateParamsModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelAccountCreateParams(this.buffer, 4)
  }

  /**
   * Get the AccountCreateParams model
   * @this {!AccountCreateParamsModel}
   * @returns {!FieldModelAccountCreateParams} model AccountCreateParams model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!AccountCreateParamsModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!AccountCreateParamsModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return AccountCreateParamsModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!AccountCreateParamsModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelAccountCreateParams.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!AccountCreateParamsModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!AccountCreateParamsModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!AccountCreateParamsModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!AccountCreateParamsModel}
   * @param {!AccountCreateParams} value AccountCreateParams value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!AccountCreateParamsModel}
   * @param {!AccountCreateParams} value AccountCreateParams value, defaults is new AccountCreateParams()
   * @return {!object} Deserialized AccountCreateParams value and its size
   */
  deserialize (value = new AccountCreateParams()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new AccountCreateParams(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new AccountCreateParams(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!AccountCreateParamsModel}
   * @param {!number} prev Previous AccountCreateParams model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { AccountCreateParamsModel };

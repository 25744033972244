
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {AuthenticationType} from './AuthenticationType';
import {FieldModelAuthenticationType} from './AuthenticationType';
import {AccountingType} from './AccountingType';
import {FieldModelAccountingType} from './AccountingType';
import {ReportsSubscriptionType} from './ReportsSubscriptionType';
import {FieldModelReportsSubscriptionType} from './ReportsSubscriptionType';
import {FeaturesToggleForce} from './FeaturesToggleForce';
import {FieldModelFeaturesToggleForce} from './FeaturesToggleForce';
import {SocialTradingAccountType} from './SocialTradingAccountType';
import {FieldModelSocialTradingAccountType} from './SocialTradingAccountType';
import {SocialTradingMaster} from './SocialTradingMaster';
import {FieldModelSocialTradingMaster} from './SocialTradingMaster';
import {SocialTradingInvestor} from './SocialTradingInvestor';
import {FieldModelSocialTradingInvestor} from './SocialTradingInvestor';

/**
 * Account struct
 */
class Account {
  /**
   * Initialize struct
   * @param {!number=} login
   * @param {!number=} actualVersion
   * @param {!UUID=} accountGroupId
   * @param {!string=} name
   * @param {!string=} country
   * @param {!string=} city
   * @param {!string=} state
   * @param {!string=} zipCode
   * @param {!string=} address
   * @param {!string=} phone
   * @param {!string=} email
   * @param {!string=} identifier
   * @param {!string=} status
   * @param {!string=} comment
   * @param {!string=} description
   * @param {boolean=} isEnabled
   * @param {boolean=} deprecated_IsOTPEnabled
   * @param {boolean=} isTradeEnabled
   * @param {!AuthenticationType=} authenticationType
   * @param {!string=} passwordDigest
   * @param {!string=} deprecated_InvestorPasswordDigest
   * @param {!string=} phonePasswordDigest
   * @param {string=} deprecated_OTPDigest
   * @param {!AccountingType=} accountingType
   * @param {number=} agentAccount
   * @param {!number=} maxPendingOrders
   * @param {!Date=} created
   * @param {!Date=} modified
   * @param {!Date=} archived
   * @param {!Date=} deleted
   * @param {!Date=} lastLogin
   * @param {!string=} group
   * @param {!string=} platform
   * @param {!ReportsSubscriptionType=} reportsSubscriptionType
   * @param {!number=} maxOrders
   * @param {string=} externalClientId
   * @param {string=} externalAccountId
   * @param {!string=} localLanguageName
   * @param {!FeaturesToggleForce=} featuresToggleForce
   * @param {!SocialTradingAccountType=} socialTradingAccountType
   * @param {SocialTradingMaster=} socialTradingMaster
   * @param {SocialTradingInvestor=} socialTradingInvestor
   * @param {!number=} disabledEventTypesMask
   * @param {!string=} languageName
   * @constructor
   */
  constructor (login = new UInt64(0, 0), actualVersion = new UInt64(0, 0), accountGroupId = new UUID(), name = '', country = '', city = '', state = '', zipCode = '', address = '', phone = '', email = '', identifier = '', status = '', comment = '', description = '', isEnabled = null, deprecated_IsOTPEnabled = null, isTradeEnabled = null, authenticationType = new AuthenticationType(), passwordDigest = '', deprecated_InvestorPasswordDigest = '', phonePasswordDigest = '', deprecated_OTPDigest = null, accountingType = new AccountingType(), agentAccount = null, maxPendingOrders = new UInt64(0, 0), created = new Date(0), modified = new Date(0), archived = new Date(0), deleted = new Date(0), lastLogin = new Date(0), group = '', platform = '', reportsSubscriptionType = new ReportsSubscriptionType(), maxOrders = new UInt64(0, 0), externalClientId = null, externalAccountId = null, localLanguageName = '', featuresToggleForce = new FeaturesToggleForce(), socialTradingAccountType = new SocialTradingAccountType(), socialTradingMaster = null, socialTradingInvestor = null, disabledEventTypesMask = new UInt64(0, 0), languageName = '') {
    this.Login = login
    this.ActualVersion = actualVersion
    this.AccountGroupId = accountGroupId
    this.Name = name
    this.Country = country
    this.City = city
    this.State = state
    this.ZipCode = zipCode
    this.Address = address
    this.Phone = phone
    this.Email = email
    this.Identifier = identifier
    this.Status = status
    this.Comment = comment
    this.Description = description
    this.IsEnabled = isEnabled
    this.Deprecated_IsOTPEnabled = deprecated_IsOTPEnabled
    this.IsTradeEnabled = isTradeEnabled
    this.AuthenticationType = authenticationType
    this.PasswordDigest = passwordDigest
    this.Deprecated_InvestorPasswordDigest = deprecated_InvestorPasswordDigest
    this.PhonePasswordDigest = phonePasswordDigest
    this.Deprecated_OTPDigest = deprecated_OTPDigest
    this.AccountingType = accountingType
    this.AgentAccount = agentAccount
    this.MaxPendingOrders = maxPendingOrders
    this.Created = created
    this.Modified = modified
    this.Archived = archived
    this.Deleted = deleted
    this.LastLogin = lastLogin
    this.Group = group
    this.Platform = platform
    this.ReportsSubscriptionType = reportsSubscriptionType
    this.MaxOrders = maxOrders
    this.ExternalClientId = externalClientId
    this.ExternalAccountId = externalAccountId
    this.LocalLanguageName = localLanguageName
    this.FeaturesToggleForce = featuresToggleForce
    this.SocialTradingAccountType = socialTradingAccountType
    this.SocialTradingMaster = socialTradingMaster
    this.SocialTradingInvestor = socialTradingInvestor
    this.DisabledEventTypesMask = disabledEventTypesMask
    this.LanguageName = languageName
  }

  /**
   * Copy struct (shallow copy)
   * @this {!Account}
   * @param {!Account} other Other struct
   * @returns {!Account} This struct
   */
  copy (other) {
    if (other.Login != null) {
      this.Login = UInt64.fromNumber(other.Login)
    } else {
      this.Login = null
    }
    if (other.ActualVersion != null) {
      this.ActualVersion = UInt64.fromNumber(other.ActualVersion)
    } else {
      this.ActualVersion = null
    }
    if (other.AccountGroupId != null) {
      this.AccountGroupId = new UUID(other.AccountGroupId)
    } else {
      this.AccountGroupId = null
    }
    if (other.Name != null) {
      this.Name = other.Name
    } else {
      this.Name = null
    }
    if (other.Country != null) {
      this.Country = other.Country
    } else {
      this.Country = null
    }
    if (other.City != null) {
      this.City = other.City
    } else {
      this.City = null
    }
    if (other.State != null) {
      this.State = other.State
    } else {
      this.State = null
    }
    if (other.ZipCode != null) {
      this.ZipCode = other.ZipCode
    } else {
      this.ZipCode = null
    }
    if (other.Address != null) {
      this.Address = other.Address
    } else {
      this.Address = null
    }
    if (other.Phone != null) {
      this.Phone = other.Phone
    } else {
      this.Phone = null
    }
    if (other.Email != null) {
      this.Email = other.Email
    } else {
      this.Email = null
    }
    if (other.Identifier != null) {
      this.Identifier = other.Identifier
    } else {
      this.Identifier = null
    }
    if (other.Status != null) {
      this.Status = other.Status
    } else {
      this.Status = null
    }
    if (other.Comment != null) {
      this.Comment = other.Comment
    } else {
      this.Comment = null
    }
    if (other.Description != null) {
      this.Description = other.Description
    } else {
      this.Description = null
    }
    if (other.IsEnabled != null) {
      this.IsEnabled = other.IsEnabled
    } else {
      this.IsEnabled = null
    }
    if (other.Deprecated_IsOTPEnabled != null) {
      this.Deprecated_IsOTPEnabled = other.Deprecated_IsOTPEnabled
    } else {
      this.Deprecated_IsOTPEnabled = null
    }
    if (other.IsTradeEnabled != null) {
      this.IsTradeEnabled = other.IsTradeEnabled
    } else {
      this.IsTradeEnabled = null
    }
    if (other.AuthenticationType != null) {
      this.AuthenticationType = AuthenticationType.fromObject(other.AuthenticationType)
    } else {
      this.AuthenticationType = null
    }
    if (other.PasswordDigest != null) {
      this.PasswordDigest = other.PasswordDigest
    } else {
      this.PasswordDigest = null
    }
    if (other.Deprecated_InvestorPasswordDigest != null) {
      this.Deprecated_InvestorPasswordDigest = other.Deprecated_InvestorPasswordDigest
    } else {
      this.Deprecated_InvestorPasswordDigest = null
    }
    if (other.PhonePasswordDigest != null) {
      this.PhonePasswordDigest = other.PhonePasswordDigest
    } else {
      this.PhonePasswordDigest = null
    }
    if (other.Deprecated_OTPDigest != null) {
      this.Deprecated_OTPDigest = other.Deprecated_OTPDigest
    } else {
      this.Deprecated_OTPDigest = null
    }
    if (other.AccountingType != null) {
      this.AccountingType = AccountingType.fromObject(other.AccountingType)
    } else {
      this.AccountingType = null
    }
    if (other.AgentAccount != null) {
      this.AgentAccount = UInt64.fromNumber(other.AgentAccount)
    } else {
      this.AgentAccount = null
    }
    if (other.MaxPendingOrders != null) {
      this.MaxPendingOrders = UInt64.fromNumber(other.MaxPendingOrders)
    } else {
      this.MaxPendingOrders = null
    }
    if (other.Created != null) {
      if (other.Created instanceof Date) {
        this.Created = new Date(other.Created.getTime())
      } else {
        this.Created = new Date(Math.round(other.Created / 1000000))
      }
    } else {
      this.Created = null
    }
    if (other.Modified != null) {
      if (other.Modified instanceof Date) {
        this.Modified = new Date(other.Modified.getTime())
      } else {
        this.Modified = new Date(Math.round(other.Modified / 1000000))
      }
    } else {
      this.Modified = null
    }
    if (other.Archived != null) {
      if (other.Archived instanceof Date) {
        this.Archived = new Date(other.Archived.getTime())
      } else {
        this.Archived = new Date(Math.round(other.Archived / 1000000))
      }
    } else {
      this.Archived = null
    }
    if (other.Deleted != null) {
      if (other.Deleted instanceof Date) {
        this.Deleted = new Date(other.Deleted.getTime())
      } else {
        this.Deleted = new Date(Math.round(other.Deleted / 1000000))
      }
    } else {
      this.Deleted = null
    }
    if (other.LastLogin != null) {
      if (other.LastLogin instanceof Date) {
        this.LastLogin = new Date(other.LastLogin.getTime())
      } else {
        this.LastLogin = new Date(Math.round(other.LastLogin / 1000000))
      }
    } else {
      this.LastLogin = null
    }
    if (other.Group != null) {
      this.Group = other.Group
    } else {
      this.Group = null
    }
    if (other.Platform != null) {
      this.Platform = other.Platform
    } else {
      this.Platform = null
    }
    if (other.ReportsSubscriptionType != null) {
      this.ReportsSubscriptionType = ReportsSubscriptionType.fromObject(other.ReportsSubscriptionType)
    } else {
      this.ReportsSubscriptionType = null
    }
    if (other.MaxOrders != null) {
      this.MaxOrders = UInt64.fromNumber(other.MaxOrders)
    } else {
      this.MaxOrders = null
    }
    if (other.ExternalClientId != null) {
      this.ExternalClientId = other.ExternalClientId
    } else {
      this.ExternalClientId = null
    }
    if (other.ExternalAccountId != null) {
      this.ExternalAccountId = other.ExternalAccountId
    } else {
      this.ExternalAccountId = null
    }
    if (other.LocalLanguageName != null) {
      this.LocalLanguageName = other.LocalLanguageName
    } else {
      this.LocalLanguageName = null
    }
    if (other.FeaturesToggleForce != null) {
      this.FeaturesToggleForce = FeaturesToggleForce.fromObject(other.FeaturesToggleForce)
    } else {
      this.FeaturesToggleForce = null
    }
    if (other.SocialTradingAccountType != null) {
      this.SocialTradingAccountType = SocialTradingAccountType.fromObject(other.SocialTradingAccountType)
    } else {
      this.SocialTradingAccountType = null
    }
    if (other.SocialTradingMaster != null) {
      this.SocialTradingMaster = SocialTradingMaster.fromObject(other.SocialTradingMaster)
    } else {
      this.SocialTradingMaster = null
    }
    if (other.SocialTradingInvestor != null) {
      this.SocialTradingInvestor = SocialTradingInvestor.fromObject(other.SocialTradingInvestor)
    } else {
      this.SocialTradingInvestor = null
    }
    if (other.DisabledEventTypesMask != null) {
      this.DisabledEventTypesMask = UInt64.fromNumber(other.DisabledEventTypesMask)
    } else {
      this.DisabledEventTypesMask = null
    }
    if (other.LanguageName != null) {
      this.LanguageName = other.LanguageName
    } else {
      this.LanguageName = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!Account}
   * @returns {!Account} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new AccountModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new AccountModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!Account}
   * @param {!Account} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof Account)) {
      throw new TypeError('Instance of Account is required!')
    }
    // noinspection RedundantIfStatementJS
    if (this.Login !== other.Login) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!Account}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Login: ((this.Login != null) ? this.Login.toNumber() : null),
      ActualVersion: ((this.ActualVersion != null) ? this.ActualVersion.toNumber() : null),
      AccountGroupId: ((this.AccountGroupId != null) ? this.AccountGroupId.toString() : null),
      Name: ((this.Name != null) ? this.Name : null),
      Country: ((this.Country != null) ? this.Country : null),
      City: ((this.City != null) ? this.City : null),
      State: ((this.State != null) ? this.State : null),
      ZipCode: ((this.ZipCode != null) ? this.ZipCode : null),
      Address: ((this.Address != null) ? this.Address : null),
      Phone: ((this.Phone != null) ? this.Phone : null),
      Email: ((this.Email != null) ? this.Email : null),
      Identifier: ((this.Identifier != null) ? this.Identifier : null),
      Status: ((this.Status != null) ? this.Status : null),
      Comment: ((this.Comment != null) ? this.Comment : null),
      Description: ((this.Description != null) ? this.Description : null),
      IsEnabled: ((this.IsEnabled != null) ? this.IsEnabled : null),
      Deprecated_IsOTPEnabled: ((this.Deprecated_IsOTPEnabled != null) ? this.Deprecated_IsOTPEnabled : null),
      IsTradeEnabled: ((this.IsTradeEnabled != null) ? this.IsTradeEnabled : null),
      AuthenticationType: ((this.AuthenticationType != null) ? this.AuthenticationType : null),
      PasswordDigest: ((this.PasswordDigest != null) ? this.PasswordDigest : null),
      Deprecated_InvestorPasswordDigest: ((this.Deprecated_InvestorPasswordDigest != null) ? this.Deprecated_InvestorPasswordDigest : null),
      PhonePasswordDigest: ((this.PhonePasswordDigest != null) ? this.PhonePasswordDigest : null),
      Deprecated_OTPDigest: ((this.Deprecated_OTPDigest != null) ? this.Deprecated_OTPDigest : null),
      AccountingType: ((this.AccountingType != null) ? this.AccountingType : null),
      AgentAccount: ((this.AgentAccount != null) ? this.AgentAccount.toNumber() : null),
      MaxPendingOrders: ((this.MaxPendingOrders != null) ? this.MaxPendingOrders.toNumber() : null),
      Created: ((this.Created != null) ? (this.Created.getTime() * 1000000) : null),
      Modified: ((this.Modified != null) ? (this.Modified.getTime() * 1000000) : null),
      Archived: ((this.Archived != null) ? (this.Archived.getTime() * 1000000) : null),
      Deleted: ((this.Deleted != null) ? (this.Deleted.getTime() * 1000000) : null),
      LastLogin: ((this.LastLogin != null) ? (this.LastLogin.getTime() * 1000000) : null),
      Group: ((this.Group != null) ? this.Group : null),
      Platform: ((this.Platform != null) ? this.Platform : null),
      ReportsSubscriptionType: ((this.ReportsSubscriptionType != null) ? this.ReportsSubscriptionType : null),
      MaxOrders: ((this.MaxOrders != null) ? this.MaxOrders.toNumber() : null),
      ExternalClientId: ((this.ExternalClientId != null) ? this.ExternalClientId : null),
      ExternalAccountId: ((this.ExternalAccountId != null) ? this.ExternalAccountId : null),
      LocalLanguageName: ((this.LocalLanguageName != null) ? this.LocalLanguageName : null),
      FeaturesToggleForce: ((this.FeaturesToggleForce != null) ? this.FeaturesToggleForce : null),
      SocialTradingAccountType: ((this.SocialTradingAccountType != null) ? this.SocialTradingAccountType : null),
      SocialTradingMaster: ((this.SocialTradingMaster != null) ? this.SocialTradingMaster : null),
      SocialTradingInvestor: ((this.SocialTradingInvestor != null) ? this.SocialTradingInvestor : null),
      DisabledEventTypesMask: ((this.DisabledEventTypesMask != null) ? this.DisabledEventTypesMask.toNumber() : null),
      LanguageName: ((this.LanguageName != null) ? this.LanguageName : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return Account.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!Account} other Object value
   * @returns {!Account} Created struct
   */
  static fromObject (other) {
    return new Account().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!Account}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return Account.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!Account}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 45
  }
}

export { Account };

/**
 * Fast Binary Encoding Account field model
 */
class FieldModelAccount extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Login = new fbe.FieldModelUInt64(buffer, 4 + 4)
    this.ActualVersion = new fbe.FieldModelUInt64(buffer, this.Login.fbeOffset + this.Login.fbeSize)
    this.AccountGroupId = new fbe.FieldModelUUID(buffer, this.ActualVersion.fbeOffset + this.ActualVersion.fbeSize)
    this.Name = new fbe.FieldModelString(buffer, this.AccountGroupId.fbeOffset + this.AccountGroupId.fbeSize)
    this.Country = new fbe.FieldModelString(buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.City = new fbe.FieldModelString(buffer, this.Country.fbeOffset + this.Country.fbeSize)
    this.State = new fbe.FieldModelString(buffer, this.City.fbeOffset + this.City.fbeSize)
    this.ZipCode = new fbe.FieldModelString(buffer, this.State.fbeOffset + this.State.fbeSize)
    this.Address = new fbe.FieldModelString(buffer, this.ZipCode.fbeOffset + this.ZipCode.fbeSize)
    this.Phone = new fbe.FieldModelString(buffer, this.Address.fbeOffset + this.Address.fbeSize)
    this.Email = new fbe.FieldModelString(buffer, this.Phone.fbeOffset + this.Phone.fbeSize)
    this.Identifier = new fbe.FieldModelString(buffer, this.Email.fbeOffset + this.Email.fbeSize)
    this.Status = new fbe.FieldModelString(buffer, this.Identifier.fbeOffset + this.Identifier.fbeSize)
    this.Comment = new fbe.FieldModelString(buffer, this.Status.fbeOffset + this.Status.fbeSize)
    this.Description = new fbe.FieldModelString(buffer, this.Comment.fbeOffset + this.Comment.fbeSize)
    this.IsEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.Description.fbeOffset + this.Description.fbeSize), buffer, this.Description.fbeOffset + this.Description.fbeSize)
    this.Deprecated_IsOTPEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.IsEnabled.fbeOffset + this.IsEnabled.fbeSize), buffer, this.IsEnabled.fbeOffset + this.IsEnabled.fbeSize)
    this.IsTradeEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.Deprecated_IsOTPEnabled.fbeOffset + this.Deprecated_IsOTPEnabled.fbeSize), buffer, this.Deprecated_IsOTPEnabled.fbeOffset + this.Deprecated_IsOTPEnabled.fbeSize)
    this.AuthenticationType = new FieldModelAuthenticationType(buffer, this.IsTradeEnabled.fbeOffset + this.IsTradeEnabled.fbeSize)
    this.PasswordDigest = new fbe.FieldModelString(buffer, this.AuthenticationType.fbeOffset + this.AuthenticationType.fbeSize)
    this.Deprecated_InvestorPasswordDigest = new fbe.FieldModelString(buffer, this.PasswordDigest.fbeOffset + this.PasswordDigest.fbeSize)
    this.PhonePasswordDigest = new fbe.FieldModelString(buffer, this.Deprecated_InvestorPasswordDigest.fbeOffset + this.Deprecated_InvestorPasswordDigest.fbeSize)
    this.Deprecated_OTPDigest = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.PhonePasswordDigest.fbeOffset + this.PhonePasswordDigest.fbeSize), buffer, this.PhonePasswordDigest.fbeOffset + this.PhonePasswordDigest.fbeSize)
    this.AccountingType = new FieldModelAccountingType(buffer, this.Deprecated_OTPDigest.fbeOffset + this.Deprecated_OTPDigest.fbeSize)
    this.AgentAccount = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.AccountingType.fbeOffset + this.AccountingType.fbeSize), buffer, this.AccountingType.fbeOffset + this.AccountingType.fbeSize)
    this.MaxPendingOrders = new fbe.FieldModelUInt64(buffer, this.AgentAccount.fbeOffset + this.AgentAccount.fbeSize)
    this.Created = new fbe.FieldModelTimestamp(buffer, this.MaxPendingOrders.fbeOffset + this.MaxPendingOrders.fbeSize)
    this.Modified = new fbe.FieldModelTimestamp(buffer, this.Created.fbeOffset + this.Created.fbeSize)
    this.Archived = new fbe.FieldModelTimestamp(buffer, this.Modified.fbeOffset + this.Modified.fbeSize)
    this.Deleted = new fbe.FieldModelTimestamp(buffer, this.Archived.fbeOffset + this.Archived.fbeSize)
    this.LastLogin = new fbe.FieldModelTimestamp(buffer, this.Deleted.fbeOffset + this.Deleted.fbeSize)
    this.Group = new fbe.FieldModelString(buffer, this.LastLogin.fbeOffset + this.LastLogin.fbeSize)
    this.Platform = new fbe.FieldModelString(buffer, this.Group.fbeOffset + this.Group.fbeSize)
    this.ReportsSubscriptionType = new FieldModelReportsSubscriptionType(buffer, this.Platform.fbeOffset + this.Platform.fbeSize)
    this.MaxOrders = new fbe.FieldModelUInt64(buffer, this.ReportsSubscriptionType.fbeOffset + this.ReportsSubscriptionType.fbeSize)
    this.ExternalClientId = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.MaxOrders.fbeOffset + this.MaxOrders.fbeSize), buffer, this.MaxOrders.fbeOffset + this.MaxOrders.fbeSize)
    this.ExternalAccountId = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.ExternalClientId.fbeOffset + this.ExternalClientId.fbeSize), buffer, this.ExternalClientId.fbeOffset + this.ExternalClientId.fbeSize)
    this.LocalLanguageName = new fbe.FieldModelString(buffer, this.ExternalAccountId.fbeOffset + this.ExternalAccountId.fbeSize)
    this.FeaturesToggleForce = new FieldModelFeaturesToggleForce(buffer, this.LocalLanguageName.fbeOffset + this.LocalLanguageName.fbeSize)
    this.SocialTradingAccountType = new FieldModelSocialTradingAccountType(buffer, this.FeaturesToggleForce.fbeOffset + this.FeaturesToggleForce.fbeSize)
    this.SocialTradingMaster = new fbe.FieldModelOptional(new FieldModelSocialTradingMaster(buffer, this.SocialTradingAccountType.fbeOffset + this.SocialTradingAccountType.fbeSize), buffer, this.SocialTradingAccountType.fbeOffset + this.SocialTradingAccountType.fbeSize)
    this.SocialTradingInvestor = new fbe.FieldModelOptional(new FieldModelSocialTradingInvestor(buffer, this.SocialTradingMaster.fbeOffset + this.SocialTradingMaster.fbeSize), buffer, this.SocialTradingMaster.fbeOffset + this.SocialTradingMaster.fbeSize)
    this.DisabledEventTypesMask = new fbe.FieldModelUInt64(buffer, this.SocialTradingInvestor.fbeOffset + this.SocialTradingInvestor.fbeSize)
    this.LanguageName = new fbe.FieldModelString(buffer, this.DisabledEventTypesMask.fbeOffset + this.DisabledEventTypesMask.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelAccount}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelAccount}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Login.fbeSize + this.ActualVersion.fbeSize + this.AccountGroupId.fbeSize + this.Name.fbeSize + this.Country.fbeSize + this.City.fbeSize + this.State.fbeSize + this.ZipCode.fbeSize + this.Address.fbeSize + this.Phone.fbeSize + this.Email.fbeSize + this.Identifier.fbeSize + this.Status.fbeSize + this.Comment.fbeSize + this.Description.fbeSize + this.IsEnabled.fbeSize + this.Deprecated_IsOTPEnabled.fbeSize + this.IsTradeEnabled.fbeSize + this.AuthenticationType.fbeSize + this.PasswordDigest.fbeSize + this.Deprecated_InvestorPasswordDigest.fbeSize + this.PhonePasswordDigest.fbeSize + this.Deprecated_OTPDigest.fbeSize + this.AccountingType.fbeSize + this.AgentAccount.fbeSize + this.MaxPendingOrders.fbeSize + this.Created.fbeSize + this.Modified.fbeSize + this.Archived.fbeSize + this.Deleted.fbeSize + this.LastLogin.fbeSize + this.Group.fbeSize + this.Platform.fbeSize + this.ReportsSubscriptionType.fbeSize + this.MaxOrders.fbeSize + this.ExternalClientId.fbeSize + this.ExternalAccountId.fbeSize + this.LocalLanguageName.fbeSize + this.FeaturesToggleForce.fbeSize + this.SocialTradingAccountType.fbeSize + this.SocialTradingMaster.fbeSize + this.SocialTradingInvestor.fbeSize + this.DisabledEventTypesMask.fbeSize + this.LanguageName.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelAccount}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Login.fbeExtra + this.ActualVersion.fbeExtra + this.AccountGroupId.fbeExtra + this.Name.fbeExtra + this.Country.fbeExtra + this.City.fbeExtra + this.State.fbeExtra + this.ZipCode.fbeExtra + this.Address.fbeExtra + this.Phone.fbeExtra + this.Email.fbeExtra + this.Identifier.fbeExtra + this.Status.fbeExtra + this.Comment.fbeExtra + this.Description.fbeExtra + this.IsEnabled.fbeExtra + this.Deprecated_IsOTPEnabled.fbeExtra + this.IsTradeEnabled.fbeExtra + this.AuthenticationType.fbeExtra + this.PasswordDigest.fbeExtra + this.Deprecated_InvestorPasswordDigest.fbeExtra + this.PhonePasswordDigest.fbeExtra + this.Deprecated_OTPDigest.fbeExtra + this.AccountingType.fbeExtra + this.AgentAccount.fbeExtra + this.MaxPendingOrders.fbeExtra + this.Created.fbeExtra + this.Modified.fbeExtra + this.Archived.fbeExtra + this.Deleted.fbeExtra + this.LastLogin.fbeExtra + this.Group.fbeExtra + this.Platform.fbeExtra + this.ReportsSubscriptionType.fbeExtra + this.MaxOrders.fbeExtra + this.ExternalClientId.fbeExtra + this.ExternalAccountId.fbeExtra + this.LocalLanguageName.fbeExtra + this.FeaturesToggleForce.fbeExtra + this.SocialTradingAccountType.fbeExtra + this.SocialTradingMaster.fbeExtra + this.SocialTradingInvestor.fbeExtra + this.DisabledEventTypesMask.fbeExtra + this.LanguageName.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelAccount}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelAccount.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelAccount}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 45
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelAccount}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelAccount}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Login.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Login.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.ActualVersion.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ActualVersion.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActualVersion.fbeSize

    if ((fbeCurrentSize + this.AccountGroupId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountGroupId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupId.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Country.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Country.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Country.fbeSize

    if ((fbeCurrentSize + this.City.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.City.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.City.fbeSize

    if ((fbeCurrentSize + this.State.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.State.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.State.fbeSize

    if ((fbeCurrentSize + this.ZipCode.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ZipCode.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ZipCode.fbeSize

    if ((fbeCurrentSize + this.Address.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Address.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Address.fbeSize

    if ((fbeCurrentSize + this.Phone.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Phone.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Phone.fbeSize

    if ((fbeCurrentSize + this.Email.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Email.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Email.fbeSize

    if ((fbeCurrentSize + this.Identifier.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Identifier.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Identifier.fbeSize

    if ((fbeCurrentSize + this.Status.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Status.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Status.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Comment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Description.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.Deprecated_IsOTPEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deprecated_IsOTPEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated_IsOTPEnabled.fbeSize

    if ((fbeCurrentSize + this.IsTradeEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsTradeEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsTradeEnabled.fbeSize

    if ((fbeCurrentSize + this.AuthenticationType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AuthenticationType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AuthenticationType.fbeSize

    if ((fbeCurrentSize + this.PasswordDigest.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PasswordDigest.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PasswordDigest.fbeSize

    if ((fbeCurrentSize + this.Deprecated_InvestorPasswordDigest.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deprecated_InvestorPasswordDigest.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated_InvestorPasswordDigest.fbeSize

    if ((fbeCurrentSize + this.PhonePasswordDigest.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PhonePasswordDigest.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PhonePasswordDigest.fbeSize

    if ((fbeCurrentSize + this.Deprecated_OTPDigest.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deprecated_OTPDigest.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated_OTPDigest.fbeSize

    if ((fbeCurrentSize + this.AccountingType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountingType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountingType.fbeSize

    if ((fbeCurrentSize + this.AgentAccount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AgentAccount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AgentAccount.fbeSize

    if ((fbeCurrentSize + this.MaxPendingOrders.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MaxPendingOrders.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxPendingOrders.fbeSize

    if ((fbeCurrentSize + this.Created.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Created.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Created.fbeSize

    if ((fbeCurrentSize + this.Modified.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Modified.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Modified.fbeSize

    if ((fbeCurrentSize + this.Archived.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Archived.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Archived.fbeSize

    if ((fbeCurrentSize + this.Deleted.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deleted.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deleted.fbeSize

    if ((fbeCurrentSize + this.LastLogin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LastLogin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LastLogin.fbeSize

    if ((fbeCurrentSize + this.Group.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Group.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Group.fbeSize

    if ((fbeCurrentSize + this.Platform.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Platform.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Platform.fbeSize

    if ((fbeCurrentSize + this.ReportsSubscriptionType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ReportsSubscriptionType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ReportsSubscriptionType.fbeSize

    if ((fbeCurrentSize + this.MaxOrders.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MaxOrders.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxOrders.fbeSize

    if ((fbeCurrentSize + this.ExternalClientId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ExternalClientId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalClientId.fbeSize

    if ((fbeCurrentSize + this.ExternalAccountId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ExternalAccountId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalAccountId.fbeSize

    if ((fbeCurrentSize + this.LocalLanguageName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LocalLanguageName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LocalLanguageName.fbeSize

    if ((fbeCurrentSize + this.FeaturesToggleForce.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.FeaturesToggleForce.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FeaturesToggleForce.fbeSize

    if ((fbeCurrentSize + this.SocialTradingAccountType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SocialTradingAccountType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingAccountType.fbeSize

    if ((fbeCurrentSize + this.SocialTradingMaster.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SocialTradingMaster.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingMaster.fbeSize

    if ((fbeCurrentSize + this.SocialTradingInvestor.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SocialTradingInvestor.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingInvestor.fbeSize

    if ((fbeCurrentSize + this.DisabledEventTypesMask.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DisabledEventTypesMask.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DisabledEventTypesMask.fbeSize

    if ((fbeCurrentSize + this.LanguageName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LanguageName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LanguageName.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelAccount}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelAccount}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelAccount}
   * @param {!Account} fbeValue Default value, defaults is new Account()
   * @returns {!Account} Account value
   */
  get (fbeValue = new Account()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelAccount}
   * @param {!Account} fbeValue Account value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Login.fbeSize) <= fbeStructSize) {
      fbeValue.Login = this.Login.get()
    } else {
      fbeValue.Login = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.ActualVersion.fbeSize) <= fbeStructSize) {
      fbeValue.ActualVersion = this.ActualVersion.get()
    } else {
      fbeValue.ActualVersion = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActualVersion.fbeSize

    if ((fbeCurrentSize + this.AccountGroupId.fbeSize) <= fbeStructSize) {
      fbeValue.AccountGroupId = this.AccountGroupId.get()
    } else {
      fbeValue.AccountGroupId = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupId.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Country.fbeSize) <= fbeStructSize) {
      fbeValue.Country = this.Country.get()
    } else {
      fbeValue.Country = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Country.fbeSize

    if ((fbeCurrentSize + this.City.fbeSize) <= fbeStructSize) {
      fbeValue.City = this.City.get()
    } else {
      fbeValue.City = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.City.fbeSize

    if ((fbeCurrentSize + this.State.fbeSize) <= fbeStructSize) {
      fbeValue.State = this.State.get()
    } else {
      fbeValue.State = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.State.fbeSize

    if ((fbeCurrentSize + this.ZipCode.fbeSize) <= fbeStructSize) {
      fbeValue.ZipCode = this.ZipCode.get()
    } else {
      fbeValue.ZipCode = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ZipCode.fbeSize

    if ((fbeCurrentSize + this.Address.fbeSize) <= fbeStructSize) {
      fbeValue.Address = this.Address.get()
    } else {
      fbeValue.Address = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Address.fbeSize

    if ((fbeCurrentSize + this.Phone.fbeSize) <= fbeStructSize) {
      fbeValue.Phone = this.Phone.get()
    } else {
      fbeValue.Phone = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Phone.fbeSize

    if ((fbeCurrentSize + this.Email.fbeSize) <= fbeStructSize) {
      fbeValue.Email = this.Email.get()
    } else {
      fbeValue.Email = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Email.fbeSize

    if ((fbeCurrentSize + this.Identifier.fbeSize) <= fbeStructSize) {
      fbeValue.Identifier = this.Identifier.get()
    } else {
      fbeValue.Identifier = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Identifier.fbeSize

    if ((fbeCurrentSize + this.Status.fbeSize) <= fbeStructSize) {
      fbeValue.Status = this.Status.get()
    } else {
      fbeValue.Status = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Status.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) <= fbeStructSize) {
      fbeValue.Comment = this.Comment.get()
    } else {
      fbeValue.Comment = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) <= fbeStructSize) {
      fbeValue.Description = this.Description.get()
    } else {
      fbeValue.Description = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsEnabled = this.IsEnabled.get()
    } else {
      fbeValue.IsEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.Deprecated_IsOTPEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.Deprecated_IsOTPEnabled = this.Deprecated_IsOTPEnabled.get()
    } else {
      fbeValue.Deprecated_IsOTPEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated_IsOTPEnabled.fbeSize

    if ((fbeCurrentSize + this.IsTradeEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsTradeEnabled = this.IsTradeEnabled.get()
    } else {
      fbeValue.IsTradeEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsTradeEnabled.fbeSize

    if ((fbeCurrentSize + this.AuthenticationType.fbeSize) <= fbeStructSize) {
      fbeValue.AuthenticationType = this.AuthenticationType.get()
    } else {
      fbeValue.AuthenticationType = new AuthenticationType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AuthenticationType.fbeSize

    if ((fbeCurrentSize + this.PasswordDigest.fbeSize) <= fbeStructSize) {
      fbeValue.PasswordDigest = this.PasswordDigest.get()
    } else {
      fbeValue.PasswordDigest = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PasswordDigest.fbeSize

    if ((fbeCurrentSize + this.Deprecated_InvestorPasswordDigest.fbeSize) <= fbeStructSize) {
      fbeValue.Deprecated_InvestorPasswordDigest = this.Deprecated_InvestorPasswordDigest.get()
    } else {
      fbeValue.Deprecated_InvestorPasswordDigest = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated_InvestorPasswordDigest.fbeSize

    if ((fbeCurrentSize + this.PhonePasswordDigest.fbeSize) <= fbeStructSize) {
      fbeValue.PhonePasswordDigest = this.PhonePasswordDigest.get()
    } else {
      fbeValue.PhonePasswordDigest = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PhonePasswordDigest.fbeSize

    if ((fbeCurrentSize + this.Deprecated_OTPDigest.fbeSize) <= fbeStructSize) {
      fbeValue.Deprecated_OTPDigest = this.Deprecated_OTPDigest.get()
    } else {
      fbeValue.Deprecated_OTPDigest = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated_OTPDigest.fbeSize

    if ((fbeCurrentSize + this.AccountingType.fbeSize) <= fbeStructSize) {
      fbeValue.AccountingType = this.AccountingType.get()
    } else {
      fbeValue.AccountingType = new AccountingType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountingType.fbeSize

    if ((fbeCurrentSize + this.AgentAccount.fbeSize) <= fbeStructSize) {
      fbeValue.AgentAccount = this.AgentAccount.get()
    } else {
      fbeValue.AgentAccount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AgentAccount.fbeSize

    if ((fbeCurrentSize + this.MaxPendingOrders.fbeSize) <= fbeStructSize) {
      fbeValue.MaxPendingOrders = this.MaxPendingOrders.get()
    } else {
      fbeValue.MaxPendingOrders = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxPendingOrders.fbeSize

    if ((fbeCurrentSize + this.Created.fbeSize) <= fbeStructSize) {
      fbeValue.Created = this.Created.get()
    } else {
      fbeValue.Created = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Created.fbeSize

    if ((fbeCurrentSize + this.Modified.fbeSize) <= fbeStructSize) {
      fbeValue.Modified = this.Modified.get()
    } else {
      fbeValue.Modified = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Modified.fbeSize

    if ((fbeCurrentSize + this.Archived.fbeSize) <= fbeStructSize) {
      fbeValue.Archived = this.Archived.get()
    } else {
      fbeValue.Archived = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Archived.fbeSize

    if ((fbeCurrentSize + this.Deleted.fbeSize) <= fbeStructSize) {
      fbeValue.Deleted = this.Deleted.get()
    } else {
      fbeValue.Deleted = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deleted.fbeSize

    if ((fbeCurrentSize + this.LastLogin.fbeSize) <= fbeStructSize) {
      fbeValue.LastLogin = this.LastLogin.get()
    } else {
      fbeValue.LastLogin = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LastLogin.fbeSize

    if ((fbeCurrentSize + this.Group.fbeSize) <= fbeStructSize) {
      fbeValue.Group = this.Group.get()
    } else {
      fbeValue.Group = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Group.fbeSize

    if ((fbeCurrentSize + this.Platform.fbeSize) <= fbeStructSize) {
      fbeValue.Platform = this.Platform.get()
    } else {
      fbeValue.Platform = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Platform.fbeSize

    if ((fbeCurrentSize + this.ReportsSubscriptionType.fbeSize) <= fbeStructSize) {
      fbeValue.ReportsSubscriptionType = this.ReportsSubscriptionType.get()
    } else {
      fbeValue.ReportsSubscriptionType = new ReportsSubscriptionType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ReportsSubscriptionType.fbeSize

    if ((fbeCurrentSize + this.MaxOrders.fbeSize) <= fbeStructSize) {
      fbeValue.MaxOrders = this.MaxOrders.get()
    } else {
      fbeValue.MaxOrders = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxOrders.fbeSize

    if ((fbeCurrentSize + this.ExternalClientId.fbeSize) <= fbeStructSize) {
      fbeValue.ExternalClientId = this.ExternalClientId.get()
    } else {
      fbeValue.ExternalClientId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalClientId.fbeSize

    if ((fbeCurrentSize + this.ExternalAccountId.fbeSize) <= fbeStructSize) {
      fbeValue.ExternalAccountId = this.ExternalAccountId.get()
    } else {
      fbeValue.ExternalAccountId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalAccountId.fbeSize

    if ((fbeCurrentSize + this.LocalLanguageName.fbeSize) <= fbeStructSize) {
      fbeValue.LocalLanguageName = this.LocalLanguageName.get()
    } else {
      fbeValue.LocalLanguageName = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LocalLanguageName.fbeSize

    if ((fbeCurrentSize + this.FeaturesToggleForce.fbeSize) <= fbeStructSize) {
      fbeValue.FeaturesToggleForce = this.FeaturesToggleForce.get()
    } else {
      fbeValue.FeaturesToggleForce = new FeaturesToggleForce()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FeaturesToggleForce.fbeSize

    if ((fbeCurrentSize + this.SocialTradingAccountType.fbeSize) <= fbeStructSize) {
      fbeValue.SocialTradingAccountType = this.SocialTradingAccountType.get()
    } else {
      fbeValue.SocialTradingAccountType = new SocialTradingAccountType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingAccountType.fbeSize

    if ((fbeCurrentSize + this.SocialTradingMaster.fbeSize) <= fbeStructSize) {
      fbeValue.SocialTradingMaster = this.SocialTradingMaster.get()
    } else {
      fbeValue.SocialTradingMaster = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingMaster.fbeSize

    if ((fbeCurrentSize + this.SocialTradingInvestor.fbeSize) <= fbeStructSize) {
      fbeValue.SocialTradingInvestor = this.SocialTradingInvestor.get()
    } else {
      fbeValue.SocialTradingInvestor = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingInvestor.fbeSize

    if ((fbeCurrentSize + this.DisabledEventTypesMask.fbeSize) <= fbeStructSize) {
      fbeValue.DisabledEventTypesMask = this.DisabledEventTypesMask.get()
    } else {
      fbeValue.DisabledEventTypesMask = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DisabledEventTypesMask.fbeSize

    if ((fbeCurrentSize + this.LanguageName.fbeSize) <= fbeStructSize) {
      fbeValue.LanguageName = this.LanguageName.get()
    } else {
      fbeValue.LanguageName = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LanguageName.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelAccount}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelAccount}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelAccount}
   * @param {!Account} fbeValue Account value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelAccount}
   * @param {Account} fbeValue Account value
   */
  setFields (fbeValue) {
    this.Login.set(fbeValue.Login)
    this.ActualVersion.set(fbeValue.ActualVersion)
    this.AccountGroupId.set(fbeValue.AccountGroupId)
    this.Name.set(fbeValue.Name)
    this.Country.set(fbeValue.Country)
    this.City.set(fbeValue.City)
    this.State.set(fbeValue.State)
    this.ZipCode.set(fbeValue.ZipCode)
    this.Address.set(fbeValue.Address)
    this.Phone.set(fbeValue.Phone)
    this.Email.set(fbeValue.Email)
    this.Identifier.set(fbeValue.Identifier)
    this.Status.set(fbeValue.Status)
    this.Comment.set(fbeValue.Comment)
    this.Description.set(fbeValue.Description)
    this.IsEnabled.set(fbeValue.IsEnabled)
    this.Deprecated_IsOTPEnabled.set(fbeValue.Deprecated_IsOTPEnabled)
    this.IsTradeEnabled.set(fbeValue.IsTradeEnabled)
    this.AuthenticationType.set(fbeValue.AuthenticationType)
    this.PasswordDigest.set(fbeValue.PasswordDigest)
    this.Deprecated_InvestorPasswordDigest.set(fbeValue.Deprecated_InvestorPasswordDigest)
    this.PhonePasswordDigest.set(fbeValue.PhonePasswordDigest)
    this.Deprecated_OTPDigest.set(fbeValue.Deprecated_OTPDigest)
    this.AccountingType.set(fbeValue.AccountingType)
    this.AgentAccount.set(fbeValue.AgentAccount)
    this.MaxPendingOrders.set(fbeValue.MaxPendingOrders)
    this.Created.set(fbeValue.Created)
    this.Modified.set(fbeValue.Modified)
    this.Archived.set(fbeValue.Archived)
    this.Deleted.set(fbeValue.Deleted)
    this.LastLogin.set(fbeValue.LastLogin)
    this.Group.set(fbeValue.Group)
    this.Platform.set(fbeValue.Platform)
    this.ReportsSubscriptionType.set(fbeValue.ReportsSubscriptionType)
    this.MaxOrders.set(fbeValue.MaxOrders)
    this.ExternalClientId.set(fbeValue.ExternalClientId)
    this.ExternalAccountId.set(fbeValue.ExternalAccountId)
    this.LocalLanguageName.set(fbeValue.LocalLanguageName)
    this.FeaturesToggleForce.set(fbeValue.FeaturesToggleForce)
    this.SocialTradingAccountType.set(fbeValue.SocialTradingAccountType)
    this.SocialTradingMaster.set(fbeValue.SocialTradingMaster)
    this.SocialTradingInvestor.set(fbeValue.SocialTradingInvestor)
    this.DisabledEventTypesMask.set(fbeValue.DisabledEventTypesMask)
    this.LanguageName.set(fbeValue.LanguageName)
  }
}

export { FieldModelAccount };

/**
 * Fast Binary Encoding Account model
 */
class AccountModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelAccount(this.buffer, 4)
  }

  /**
   * Get the Account model
   * @this {!AccountModel}
   * @returns {!FieldModelAccount} model Account model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!AccountModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!AccountModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return AccountModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!AccountModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelAccount.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!AccountModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!AccountModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!AccountModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!AccountModel}
   * @param {!Account} value Account value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!AccountModel}
   * @param {!Account} value Account value, defaults is new Account()
   * @return {!object} Deserialized Account value and its size
   */
  deserialize (value = new Account()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new Account(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new Account(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!AccountModel}
   * @param {!number} prev Previous Account model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { AccountModel };

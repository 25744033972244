import { makeAutoObservable } from 'mobx';
import { ErrorsTracker } from '@api/errorsTracker';
import { SocketClient } from '@api/SocketClient';
import { UUID } from '@api/fbe/uuid';
import { PushNotificationTemplatesGetAllRequest } from '@api/fbe/manager/PushNotificationTemplatesGetAllRequest';
import { PushNotificationTemplateCreateRequest } from '@api/fbe/manager/PushNotificationTemplateCreateRequest';
import { PushNotificationTemplateModifyRequest } from '@api/fbe/manager/PushNotificationTemplateModifyRequest';
import { PushNotificationTemplateDeleteRequest } from '@api/fbe/manager/PushNotificationTemplateDeleteRequest';
import {
    convertPushNotificationTemplateRecordToModel,
    convertTemplatesByLanguageToApiFormat,
    PushNotificationTemplateModel,
} from '@models/pushNotifications/PushNotificationTemplate';
import { PushNotificationEventType } from '@api/fbe/core/PushNotificationEventType';
import { NewNotificationTemplates } from '@api/fbe/manager/NewNotificationTemplates';
import { Message, MessageType } from '@components/Message/Message';

export class PushNotificationTemplatesStore {
    errorTracker = new ErrorsTracker({ title: 'Push Notification Templates' });

    constructor() {
        makeAutoObservable(this);
    }

    data: PushNotificationTemplateModel[] = [];

    isLoading = false;

    setData(value: PushNotificationTemplateModel[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @ErrorsTracker.wrapApi()
    async get() {
        const request = new PushNotificationTemplatesGetAllRequest();
        this.setIsLoading(true);
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setData(data.Templates.map(convertPushNotificationTemplateRecordToModel));
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async create(newRecord: PushNotificationTemplateModel) {
        const request = new PushNotificationTemplateCreateRequest();
        if (newRecord.EventType === null) {
           Message(MessageType.error, 'Event Type is required');
           return;
        }

        request.SortOrder = newRecord.SortOrder;
        request.EventType = new PushNotificationEventType(newRecord.EventType);
        request.Templates = convertTemplatesByLanguageToApiFormat(newRecord.TemplatesByLanguage);
        request.IsDisabled = !newRecord.IsEnabled;

        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(record: PushNotificationTemplateModel) {
        const request = new PushNotificationTemplateModifyRequest();
        request.PushNotificationTemplateId = UUID.fromString(record.id);
        request.NewTemplates = new NewNotificationTemplates(
            convertTemplatesByLanguageToApiFormat(record.TemplatesByLanguage),
        );
        request.NewIsDisabled = !record.IsEnabled;

        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async delete(id: string) {
        const request = new PushNotificationTemplateDeleteRequest();
        request.PushNotificationTemplateId = UUID.fromString(id);
        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    async updateItemSortOrder(id: string, newIndex: number) {
        const targetItem = this.data.find((item) => item.id === id);
        if (targetItem) {
            const request = new PushNotificationTemplateModifyRequest();
            request.PushNotificationTemplateId = UUID.fromString(targetItem.id);
            request.NewSortOrder = newIndex;
            await SocketClient.instance.request(request, this.errorTracker);
            this.get();
        } else {
            throw new Error("Can't find Template to reorder");
        }
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

const pushNotificationTemplatesStore = new PushNotificationTemplatesStore();
export { pushNotificationTemplatesStore };

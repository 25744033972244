import { EmailTemplate } from '@api/fbe/core/EmailTemplate';

export type EmailTemplateModel = {
    id: string;
    Name: string;
    Comment: string;
    Description: string;
    EventType: number;
    EmailSubject: string;
    EmailBody: string;
};

export const convertEmailTemplateRecordToModel = (record: EmailTemplate): EmailTemplateModel => ({
    id: record.id.toString(),
    Comment: record.Comment,
    Description: record.Description,
    EventType: record.EventType.valueOf(),
    EmailBody: record.EmailBody,
    EmailSubject: record.EmailSubject,
    Name: record.Name,
});

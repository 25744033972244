import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { Login } from '../pages/Login/Login';
import './app-layout.scss';
import { UI_VERSION } from 'env/config';
import { keyMap, t } from '@localization';
import { useReaction } from '@pages/utils/commonUtils';
import { configStore } from '@store/helperStores/config';

const { Header, Content } = Layout;

function AuthLayout() {
    const [backendVersion] = useReaction(() => configStore.backendVersionString);

    useEffect(() => {
        configStore.getBackendVersion();
    }, []);

    return (
        <Layout>
            <Header className="header">
                <div className="headerContent">
                    <div className="logo">
                        <img alt="logo" className="logo_image" src='/logo.png' />
                        <div className="logo_titleBlock">
                            <p className="logo_title">{t(keyMap.pages.tradingPlatform)}</p>
                            <p className="logo_subtitle">{t(keyMap.pages.adminPanel)}</p>
                        </div>
                    </div>
                </div>
            </Header>
            <Content className="layoutContent">
                <Login />
                <div className="auth_versionBlock">
                    <p className="auth_versionText">
                        {t(keyMap.pages.frontend)}: {UI_VERSION}
                    </p>

                    <p className="auth_versionText">
                        {t(keyMap.pages.backend)}: {backendVersion || '-'}
                    </p>
                </div>
            </Content>
        </Layout>
    );
}

export default AuthLayout;

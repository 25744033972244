/* eslint-disable no-useless-escape */
import { keyMap, t } from '@localization';
import * as yup from 'yup';
import { checkStringByRegExp, spacesEndNotAllowed, spacesStartNotAllowed } from './yupValidationMethods';

export const NAME_STRING_MAX_LENGTH = 60;
export const BASE_STRING_MAX_LENGTH = 1000;
export const COMMENT_MAX_LENGTH = 1000;
export const REG_FILE_PATH = /^[a-zA-Z0-9 \!\-\@\#\.\(\)\:\,\;\$\%\^\'\"\\\/\_]+$/;
export const REG_BASE = /^[a-zA-Z0-9 \<\>\{\}\!\@\#\.\(\)\:\,\;\$\%\^\'\"\/\\\_\-\+\[\]\*]+$/;
export const REG_PASSWORD = /^[a-zA-Z0-9 \<\>\{\}\!\@\#\.\(\)\:\?\~\,\;\$\%\^\'\"\=\/\\\_\-\+\[\]\*]+$/;
export const STRONG_PASSWORD_REGEX = (minLength: number = 8) =>
    new RegExp(
        `^(?=(?:.*[A-Z])+)(?=(?:.*[a-z])+)(?=(?:.*\\d)+)(?=(?:.*[!@~?#$%^&*()\\-_=+{};:,<.>])+)([A-Za-z0-9!@?#~$%^&*()\\-_=+{};:,<.>]{${minLength},})$`,
        'gm',
    );
export const REG_NUMBER = /^[1-9]\d*(\.\d+)?$/;
export const langCodeRegExp = /^[a-zA-Z]{2}([-_][a-zA-Z]{2})?$/;
export const STRING_MAX_LENGTH_256 = 256;
export const STRING_MAX_LENGTH_64 = 64;

const FORBIDDEN_NAMES = [
    'con',
    'prn',
    'aux',
    'nul',
    'com1',
    'com2',
    'com3',
    'com4',
    'com5',
    'com6',
    'com7',
    'com8',
    'com9',
    'lpt1',
    'lpt2',
    'lpt3',
    'lpt4',
    'lpt5',
    'lpt6',
    'lpt7',
    'lpt8',
    'lpt9',
];

export const yupPortValidation = yup
    .number()
    .integer()
    .positive()
    .min(0)
    .max(65535)
    .label('Port')
    .transform((value) => (Number.isNaN(value) ? undefined : value));

export const yupBaseStringValidation = yup
    .string()
    .concat(checkStringByRegExp(REG_BASE, t(keyMap.validations.notAllowedCharsBase)))
    .concat(spacesStartNotAllowed())
    .concat(spacesEndNotAllowed())
    .max(BASE_STRING_MAX_LENGTH);

export const yupPasswordValidation = yup
    .string()
    .concat(checkStringByRegExp(REG_PASSWORD, t(keyMap.validations.notAllowedCharsBase)))
    .concat(spacesStartNotAllowed())
    .concat(spacesEndNotAllowed())
    .max(Number.MAX_SAFE_INTEGER);

export const yupPathValidation = yup
    .string()
    .concat(checkStringByRegExp(REG_FILE_PATH, t(keyMap.validations.notAllowedCharsBase)))
    .concat(spacesStartNotAllowed())
    .concat(spacesEndNotAllowed())
    .max(Number.MAX_SAFE_INTEGER);

export const yupCommonString = yup
    .string()
    .concat(spacesStartNotAllowed())
    .concat(spacesEndNotAllowed())
    .max(Number.MAX_SAFE_INTEGER);

export const yupEntityNameValidation = yup
    .string()
    .concat(spacesStartNotAllowed())
    .concat(spacesEndNotAllowed())
    .max(NAME_STRING_MAX_LENGTH)
    .test('allowedWords', t(keyMap.validations.notAllowedWords), (value) =>
        typeof value === 'string' && !!value ? !FORBIDDEN_NAMES.includes(value.toLowerCase()) : true,
    );

export const yupNumber = (precision?: number) =>
    yup
        .number()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .test('matchPrecision', t(keyMap.validations.exceedingPrecision, { key: precision ?? '' }), (value) => {
            if (precision !== undefined && typeof value === 'number') {
                const decimalPart = value.toString().split('.')[1];
                return decimalPart ? decimalPart.length <= precision : true;
            }
            return true;
        });

export const yupCommon = {
    typeSelectNullable: yup.number().default(null).nullable(),
};

export const yupLanguageCode = yupEntityNameValidation
    .label('Language')
    .concat(checkStringByRegExp(REG_BASE, t(keyMap.validations.notAllowedCharsBase)))
    .default('')
    .test('minLength', 'Min length is 2', (value) => (value ? value.length >= 2 : true))
    .test('maxLength', 'Max length is 5', (value) => (value ? value.length <= 5 : true))
    .test(
        'format',
        'Language should consists of only two letters or two letters + separator(_ or -) + two letters. Examples: en, en-US, en_US.',
        (value) => (value ? langCodeRegExp.test(value) : true),
    );

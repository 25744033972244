import { Language } from '@api/fbe/core/Language';

export type LanguageModel = {
    id: string;
    SortOrder: number;
    Name: string;
    Description: string;
    IsEnabled: boolean;
};

export const convertLanguageRecordToModel = (record: Language): LanguageModel => ({
    id: record.id.toString(),
    SortOrder: record.SortOrder,
    Name: record.Name.toString(),
    Description: record.Description.toString(),
    IsEnabled: record.IsEnabled,
});

import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {PushNotificationTemplateCreateResponse} from './PushNotificationTemplateCreateResponse';
import {PushNotificationEventType} from '../core/PushNotificationEventType';
import {FieldModelPushNotificationEventType} from '../core/PushNotificationEventType';
import {NotificationTemplate} from '../core/NotificationTemplate';
import {FieldModelNotificationTemplate} from '../core/NotificationTemplate';

/**
 * PushNotificationTemplateCreateRequest struct
 */
class PushNotificationTemplateCreateRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {number=} sortOrder
   * @param {!PushNotificationEventType=} eventType
   * @param {!Map=} templates
   * @param {!boolean=} isDisabled
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), sortOrder = null, eventType = new PushNotificationEventType(), templates = new Map(), isDisabled = false) {
    super()
    super.copy(parent)
    this.SortOrder = sortOrder
    this.EventType = eventType
    this.Templates = templates
    this.IsDisabled = isDisabled
  }

  /**
   * Copy struct (shallow copy)
   * @this {!PushNotificationTemplateCreateRequest}
   * @param {!PushNotificationTemplateCreateRequest} other Other struct
   * @returns {!PushNotificationTemplateCreateRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.SortOrder != null) {
      this.SortOrder = other.SortOrder
    } else {
      this.SortOrder = null
    }
    if (other.EventType != null) {
      this.EventType = PushNotificationEventType.fromObject(other.EventType)
    } else {
      this.EventType = null
    }
    if (other.Templates != null) {
      this.Templates = new Map()
      Object.keys(other.Templates).forEach(key => {
        if (key != null) {
          let tempKey
          tempKey = new UUID(key)
          if (other.Templates[key] != null) {
            let tempValue
            tempValue = NotificationTemplate.fromObject(other.Templates[key])
            this.Templates.set(tempKey, tempValue)
          } else {
            this.Templates.set(tempKey, null)
          }
        }
      })
    } else {
      this.Templates = null
    }
    if (other.IsDisabled != null) {
      this.IsDisabled = other.IsDisabled
    } else {
      this.IsDisabled = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!PushNotificationTemplateCreateRequest}
   * @returns {!PushNotificationTemplateCreateRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new PushNotificationTemplateCreateRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new PushNotificationTemplateCreateRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!PushNotificationTemplateCreateRequest}
   * @param {!PushNotificationTemplateCreateRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof PushNotificationTemplateCreateRequest)) {
      throw new TypeError('Instance of PushNotificationTemplateCreateRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!PushNotificationTemplateCreateRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      SortOrder: ((this.SortOrder != null) ? this.SortOrder : null),
      EventType: ((this.EventType != null) ? this.EventType : null),
      Templates: ((this.Templates != null) ? fbe.MapToObject(new Map(Array.from(this.Templates, item => [((item[0] != null) ? item[0].toString() : null), ((item[1] != null) ? item[1] : null)]))) : null),
      IsDisabled: ((this.IsDisabled != null) ? this.IsDisabled : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return PushNotificationTemplateCreateRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!PushNotificationTemplateCreateRequest} other Object value
   * @returns {!PushNotificationTemplateCreateRequest} Created struct
   */
  static fromObject (other) {
    return new PushNotificationTemplateCreateRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!PushNotificationTemplateCreateRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return PushNotificationTemplateCreateRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!PushNotificationTemplateCreateRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6585
  }
}

export { PushNotificationTemplateCreateRequest };

/**
 * Fast Binary Encoding PushNotificationTemplateCreateRequest field model
 */
class FieldModelPushNotificationTemplateCreateRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.SortOrder = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.EventType = new FieldModelPushNotificationEventType(buffer, this.SortOrder.fbeOffset + this.SortOrder.fbeSize)
    this.Templates = new fbe.FieldModelMap(new fbe.FieldModelUUID(buffer, this.EventType.fbeOffset + this.EventType.fbeSize), new FieldModelNotificationTemplate(buffer, this.EventType.fbeOffset + this.EventType.fbeSize), buffer, this.EventType.fbeOffset + this.EventType.fbeSize)
    this.IsDisabled = new fbe.FieldModelBool(buffer, this.Templates.fbeOffset + this.Templates.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelPushNotificationTemplateCreateRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelPushNotificationTemplateCreateRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelPushNotificationTemplateCreateRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.SortOrder.fbeSize + this.EventType.fbeSize + this.Templates.fbeSize + this.IsDisabled.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelPushNotificationTemplateCreateRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.SortOrder.fbeExtra + this.EventType.fbeExtra + this.Templates.fbeExtra + this.IsDisabled.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelPushNotificationTemplateCreateRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelPushNotificationTemplateCreateRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelPushNotificationTemplateCreateRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6585
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelPushNotificationTemplateCreateRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelPushNotificationTemplateCreateRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.SortOrder.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SortOrder.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrder.fbeSize

    if ((fbeCurrentSize + this.EventType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.EventType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.EventType.fbeSize

    if ((fbeCurrentSize + this.Templates.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Templates.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Templates.fbeSize

    if ((fbeCurrentSize + this.IsDisabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsDisabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsDisabled.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelPushNotificationTemplateCreateRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelPushNotificationTemplateCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelPushNotificationTemplateCreateRequest}
   * @param {!PushNotificationTemplateCreateRequest} fbeValue Default value, defaults is new PushNotificationTemplateCreateRequest()
   * @returns {!PushNotificationTemplateCreateRequest} PushNotificationTemplateCreateRequest value
   */
  get (fbeValue = new PushNotificationTemplateCreateRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelPushNotificationTemplateCreateRequest}
   * @param {!PushNotificationTemplateCreateRequest} fbeValue PushNotificationTemplateCreateRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.SortOrder.fbeSize) <= fbeStructSize) {
      fbeValue.SortOrder = this.SortOrder.get()
    } else {
      fbeValue.SortOrder = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrder.fbeSize

    if ((fbeCurrentSize + this.EventType.fbeSize) <= fbeStructSize) {
      fbeValue.EventType = this.EventType.get()
    } else {
      fbeValue.EventType = new PushNotificationEventType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.EventType.fbeSize

    if ((fbeCurrentSize + this.Templates.fbeSize) <= fbeStructSize) {
      this.Templates.get(fbeValue.Templates)
    } else {
      fbeValue.Templates.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Templates.fbeSize

    if ((fbeCurrentSize + this.IsDisabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsDisabled = this.IsDisabled.get()
    } else {
      fbeValue.IsDisabled = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsDisabled.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelPushNotificationTemplateCreateRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelPushNotificationTemplateCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelPushNotificationTemplateCreateRequest}
   * @param {!PushNotificationTemplateCreateRequest} fbeValue PushNotificationTemplateCreateRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelPushNotificationTemplateCreateRequest}
   * @param {PushNotificationTemplateCreateRequest} fbeValue PushNotificationTemplateCreateRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.SortOrder.set(fbeValue.SortOrder)
    this.EventType.set(fbeValue.EventType)
    this.Templates.set(fbeValue.Templates)
    this.IsDisabled.set(fbeValue.IsDisabled)
  }
}

export { FieldModelPushNotificationTemplateCreateRequest };

/**
 * Fast Binary Encoding PushNotificationTemplateCreateRequest model
 */
class PushNotificationTemplateCreateRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelPushNotificationTemplateCreateRequest(this.buffer, 4)
  }

  /**
   * Get the PushNotificationTemplateCreateRequest model
   * @this {!PushNotificationTemplateCreateRequestModel}
   * @returns {!FieldModelPushNotificationTemplateCreateRequest} model PushNotificationTemplateCreateRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!PushNotificationTemplateCreateRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!PushNotificationTemplateCreateRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return PushNotificationTemplateCreateRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!PushNotificationTemplateCreateRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelPushNotificationTemplateCreateRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!PushNotificationTemplateCreateRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!PushNotificationTemplateCreateRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!PushNotificationTemplateCreateRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!PushNotificationTemplateCreateRequestModel}
   * @param {!PushNotificationTemplateCreateRequest} value PushNotificationTemplateCreateRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!PushNotificationTemplateCreateRequestModel}
   * @param {!PushNotificationTemplateCreateRequest} value PushNotificationTemplateCreateRequest value, defaults is new PushNotificationTemplateCreateRequest()
   * @return {!object} Deserialized PushNotificationTemplateCreateRequest value and its size
   */
  deserialize (value = new PushNotificationTemplateCreateRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new PushNotificationTemplateCreateRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new PushNotificationTemplateCreateRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!PushNotificationTemplateCreateRequestModel}
   * @param {!number} prev Previous PushNotificationTemplateCreateRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { PushNotificationTemplateCreateRequestModel };
PushNotificationTemplateCreateRequest.__has_response = true;
PushNotificationTemplateCreateRequest.__response_class = PushNotificationTemplateCreateResponse;

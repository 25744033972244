import { makeAutoObservable } from 'mobx';
import { ErrorsTracker } from '@api/errorsTracker';
import { SocketClient } from '@api/SocketClient';
import { UUID } from '@api/fbe/uuid';
import { convertLanguageRecordToModel, LanguageModel } from '@models/pushNotifications/Language';
import { LanguageCreateRequest } from '@api/fbe/manager/LanguageCreateRequest';
import { LanguageDeleteRequest } from '@api/fbe/manager/LanguageDeleteRequest';
import { LanguageModifyRequest } from '@api/fbe/manager/LanguageModifyRequest';
import { LanguagesGetAllRequest } from '@api/fbe/manager/LanguagesGetAllRequest';

export class LanguagesStore {
    errorTracker = new ErrorsTracker({ title: 'Languages' });

    constructor() {
        makeAutoObservable(this);
    }

    data: LanguageModel[] = [];

    isLoading = false;

    setData(value: LanguageModel[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @ErrorsTracker.wrapApi()
    async get() {
        const request = new LanguagesGetAllRequest();
        this.setIsLoading(true);
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setData(data.Languages.map(convertLanguageRecordToModel));
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async create(newRecord: LanguageModel) {
        const request = new LanguageCreateRequest();

        request.SortOrder = newRecord.SortOrder;
        request.Name = newRecord.Name;
        request.Description = newRecord.Description;
        request.IsEnabled = newRecord.IsEnabled;

        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(record: LanguageModel) {
        const request = new LanguageModifyRequest();
        request.LanguageId = UUID.fromString(record.id);
        request.NewSortOrder = record.SortOrder;
        request.NewDescription = record.Description;
        request.NewIsEnabled = record.IsEnabled;

        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async delete(id: string) {
        const request = new LanguageDeleteRequest();
        request.LanguageId = UUID.fromString(id);
        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    async updateItemSortOrder(id: string, newIndex: number) {
        const targetItem = this.data.find((item) => item.id === id);
        if (targetItem) {
            const request = new LanguageModifyRequest();
            request.LanguageId = UUID.fromString(targetItem.id);
            request.NewSortOrder = newIndex;
            await SocketClient.instance.request(request, this.errorTracker);
            this.get();
        } else {
            throw new Error("Can't find Language to reorder");
        }
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

const languagesStore = new LanguagesStore();
export { languagesStore };

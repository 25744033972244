import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { Layout } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Menu, MenuItemConfig } from '@components/Menu';
import { buildMenuFromRoutes } from '@components/Menu/utils';
import { getRoutes } from 'routes/routes';
import logout from '@assets/images/logout.svg';
import './app-layout.scss';
import { authStore } from '@store/auth';
import { observer } from 'mobx-react';
import { keyMap, t } from '@localization';
import { UI_VERSION } from 'env/config';
import { useReaction } from '@pages/utils/commonUtils';
import { configStore } from '@store/helperStores/config';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { AccessContext, AdminPanelAccessMap } from '@utils/accessMap';
import { MenuShortcut } from '@components/MenuShortcut/MenuShortcut';
import { ConnectionSource } from '@components/ConnectionSource';
import { CurrentTime } from '@components/CurrentTime';
import { LoadingOverlay } from '@components/LoadingOverlay';

const { Header, Content, Sider } = Layout;

type LayoutContextT = {
    isSidebarCollapsed: boolean;
};

export const AppLayoutContext = createContext<LayoutContextT>({ isSidebarCollapsed: false });

export const AppLayout: FC<{ children: any }> = observer((props) => {
    const { children } = props;
    const accessMap = useContext<AdminPanelAccessMap | null>(AccessContext);
    const [collapsed, setCollapsed] = useState(false);
    const [shouldAnimate, setShouldAnimate] = useState(collapsed);
    const [shouldRender, setShouldRender] = useState(false);
    const [backendVersion] = useReaction(() => configStore.backendVersionString);
    const [isLoading] = useReaction<boolean>(() => authStore.isLoading);
    const routes = getRoutes(accessMap);

    useEffect(() => {
        if (window && window.innerWidth <= 992) {
            setCollapsed(true);
            setShouldAnimate(true);
        }
        setShouldRender(true);
    }, []);

    return shouldRender && accessMap ? (
        <AppLayoutContext.Provider value={{ isSidebarCollapsed: collapsed }}>
            <Layout>
                <Header className="header">
                    <div className="headerContent">
                        <div className="leftSection">
                            <div className="logo">
                                <img alt="logo" className="logo_image" src='/logo.png' />
                                <div className="logo_titleBlock">
                                    <p className="logo_title">{t(keyMap.pages.tradingPlatform)}</p>
                                    <p className="logo_subtitle">{t(keyMap.pages.adminPanel)}</p>
                                </div>
                            </div>
                            <ConnectionSource />
                        </div>
                        <div className="rightSection">
                            <CurrentTime />
                            <p className="rightSection_welcome">
                                {t(keyMap.pages.welcome)} <span className="rightSection_login">{authStore.login}</span>
                            </p>
                            <div className="logoutBlock" onClick={() => authStore.logout()}>
                                <img alt="logout" src={logout} className="logoutBlock_icon" />
                                <p className="logoutBlock_text">{t(keyMap.pages.logout)}</p>
                            </div>
                        </div>
                    </div>
                </Header>
                <LoadingOverlay isLoading={isLoading}>
                <Layout>
                    <Sider
                        collapsedWidth={40}
                        width={window.innerWidth <= 992 ? 160 : 192}
                        theme="light"
                        collapsible
                        collapsed={collapsed}
                        onCollapse={() => {
                            setCollapsed(!collapsed);
                            if (!shouldAnimate) setShouldAnimate(true);
                        }}
                        trigger={
                            collapsed ? (
                                <MenuUnfoldOutlined />
                            ) : (
                                <div className="footerBlock">
                                    <MenuFoldOutlined />
                                    <div
                                        className="versionBlock"
                                        style={shouldAnimate ? undefined : { animation: 'unset', opacity: 1 }}
                                    >
                                        <p className="versionText">
                                            {t(keyMap.pages.frontend)}: {UI_VERSION}
                                        </p>
                                        <p className="versionText">
                                            {t(keyMap.pages.backend)}: {backendVersion || '-'}
                                        </p>
                                    </div>
                                </div>
                            )
                        }
                        className="sider"
                    >
                        {!collapsed && <MenuShortcut />}
                        <Menu
                            className="outerMenu"
                            submenuItemClassName="subMenu"
                            menuItemClassName="menu-item"
                            defaultOpenKeys={collapsed ? undefined : ['admin']}
                            isMenuCollapsed={collapsed}
                            items={buildMenuFromRoutes([routes]) as MenuItemConfig[] & ItemType[]}
                        />
                    </Sider>
                    <Layout>
                        <Content className="site-layout-content">{children}</Content>
                    </Layout>
                </Layout>
                </LoadingOverlay>
            </Layout>
        </AppLayoutContext.Provider>
    ) : (
        <></>
    );
});
